import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import StoryHeader from "../components/story-header";

const Story = ({ kid_name, kid_gender, stuffy_name, stuffy_gender }) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const title = "The Collector, Part 1";
  const author = "Written by Chuck Ludeke, copyright 2021";
  const kid_name_text = capitalizeFirstLetter(kid_name);
  const kid_gender_text =
    kid_gender === "male" ? "he" : kid_gender === "female" ? "she" : "they";
  const kid_gender_possessive =
    kid_gender === "male" ? "his" : kid_gender === "female" ? "her" : "their";
  // const kid_gender_3rd = stuffy_gender === "male" ? "him" : (stuffy_gender === "female" ? "her" : "them")

  const stuffy_name_text = capitalizeFirstLetter(stuffy_name);
  const stuffy_gender_text =
    stuffy_gender === "male"
      ? "he"
      : stuffy_gender === "female"
      ? "she"
      : "they";
  const stuffy_gender_possessive =
    stuffy_gender === "male"
      ? "his"
      : stuffy_gender === "female"
      ? "her"
      : "their";
  const stuffy_gender_3rd =
    stuffy_gender === "male"
      ? "him"
      : stuffy_gender === "female"
      ? "her"
      : "them";

  const was_verb_text = stuffy_gender === "nonbinary" ? "were" : "was";

  return (
    <>
      <div>
        <StoryHeader
          title={title}
          author={author}
          background_info={
            <div>
              In this story {kid_name_text} and {stuffy_name_text} live in a
              little house in the woods with their other animal friends.
            </div>
          }
          pics={["Freddy", "Cabin", "Woods", "Bobo"]}
        />

        <div className="main-story-wrap">
          <p>
            One summer morning {stuffy_name_text} announced that{" "}
            {stuffy_gender_text} {was_verb_text} going into the woods to look
            for wild raspberries. &nbsp;
            {capitalizeFirstLetter(stuffy_gender_text)} took{" "}
            {stuffy_gender_possessive} backpack and a wooden basket.
          </p>
          <p>
            &quot;Be careful in the woods,&quot; {kid_name_text} said. &quot;And
            watch out for strangers. &quot;
          </p>
          <p>
            “I will,” {stuffy_name_text} said, and off {stuffy_gender_text}{" "}
            went, following a forest path deep into the woods.
            {capitalizeFirstLetter(stuffy_gender_text)} couldn’t find any wild
            berries growing near the trail so {stuffy_gender_text} began
            wandering off the trail until at last {stuffy_gender_text} came upon
            a small sunny clearing with a very nice raspberry bush.&nbsp;
            {capitalizeFirstLetter(stuffy_gender_text)} got out{" "}
            {stuffy_gender_possessive} basket and was very pleased with how many
            berries there were.&nbsp; When {stuffy_gender_text} had filled the
            basket, {stuffy_gender_text} decided to head home, but{" "}
            {stuffy_gender_text} couldn’t find the trail. And then{" "}
            {stuffy_gender_text} remembered that {stuffy_gender_text} had left{" "}
            {stuffy_gender_possessive} backpack by the raspberry bush, so{" "}
            {stuffy_gender_text} went back to look for it.&nbsp; But by now, it
            was starting to get dark.
          </p>
          <p>“Hello, my little friend,” said a tall stranger.</p>
          <p>
            {stuffy_name_text} was surprised.{" "}
            {capitalizeFirstLetter(stuffy_gender_text)} hadn’t expected to see
            anyone out here in the woods. And the stranger was a little scary.
            His face was quite thin, powdery white, with small black eyes, and
            his hands had long black fingernails. His long black coat touched
            the ground and he carried a big sack over his shoulder.
          </p>
          <p>“What are you doing so far from home?” the stranger asked.</p>
          <p>“Collecting berries,” {stuffy_name_text} said.</p>
          <p>“Ah! I too am a collector.”</p>
          <p>“What do you collect?” {stuffy_name_text} asked.</p>
          <p>
            “Interesting little creatures like you!” the stranger said, as he
            snatched {stuffy_name_text} in his hands and threw{" "}
            {stuffy_gender_3rd} into the sack.
          </p>
          <p>
            When evening came, {kid_name_text} was concerned that{" "}
            {stuffy_name_text} had not returned yet.&nbsp;
            {capitalizeFirstLetter(kid_gender_text)} decided to begin searching
            and asked Freddy the fox come along because he was very good at
            following animal trails. It took about an hour to find the raspberry
            bush where {stuffy_name_text} had been. {kid_name_text} shined{" "}
            {kid_gender_possessive} flashlight all around. “Look,”{" "}
            {kid_gender_text} said, “here&apos;s {stuffy_name_text}&apos;s
            backpack.”
          </p>
          <p>
            “Someone else was here too,” Freddy said. “I see big footprints and
            they lead to the Land of Caves!”
          </p>
          <p>“Could it be Bigfoot?” {kid_name_text} asked.</p>
          <p>
            “No, these footprints are long and skinny. I’ve heard of a monster
            that grabs little animals and takes them back to his museum in the
            Land of Caves. They call him the Collector.”
          </p>
          <p>
            Now the Collector had indeed captured {stuffy_name_text} and took{" "}
            {stuffy_gender_3rd} back to his stone house in the Land of Caves.{" "}
            {stuffy_name_text} was taken to a storage room and dropped into a
            large glass jar. The lid of the jar had a few holes to allow air for
            breathing. The berries {stuffy_gender_text} had collected were
            dumped out of {stuffy_gender_possessive} basket and fed to the dogs.{" "}
            {stuffy_name_text} was so upset that {stuffy_gender_text} slumped to
            the bottom of the jar and closed {stuffy_gender_possessive} eyes.{" "}
            {capitalizeFirstLetter(stuffy_gender_text)} felt like crying.
            Suddenly the collector tapped on the glass.
          </p>
          <p>
            “Are you hungry my little pet?” The Collector opened a box of
            grasshoppers, and dropped one through a hole in the jar lid. “Here
            you go,” he said. “Something to eat!” Then the collector left the
            room.
          </p>
          <p>
            {stuffy_name_text} mumbled, “I certainly don’t eat bugs!”{" "}
            {capitalizeFirstLetter(stuffy_gender_text)} picked up the
            grasshopper and threw it back out of the jar. “I have to find a way
            to escape,” {stuffy_gender_text} said. {stuffy_name_text} began to
            look around the room. There were other jars and cages, but most of
            them had snakes, or giant centipedes, or lizards in them. But one
            cage contained a little bear.
          </p>
          <p>“Hey!” {stuffy_name_text} said. “What’s your name?”</p>
          <p>
            “Bobo,” the little bear said. He had a small, squeaky voice that
            sounded like he was about to cry.
          </p>
          <p>
            “Well, don’t you worry Dodo,” {stuffy_name_text} said. “I’ll help
            you get out of here.”
          </p>
          <p>“How?”</p>
          <p>
            Just then three big dogs wandered into the room. Then had spiked
            collars and red eyes. They looked up at {stuffy_name_text} in the
            jar and started to drool.
          </p>
          <p>“Go away, you dumb dogs,” {stuffy_name_text} said.</p>
          <p>
            {capitalizeFirstLetter(stuffy_gender_text)} made a face at them and
            stuck out {stuffy_gender_possessive} tongue. Suddenly, the biggest
            dog growled and jumped up, its paws pushing at the jar{" "}
            {stuffy_name_text} was in. {stuffy_name_text}’s eyes got big as{" "}
            {stuffy_gender_text} realized that the jar was rocking backwards on
            the shelf.
          </p>
          <p>
            The jar bumped the back wall, teetered sideways for a moment, then
            slipped off the shelf, and fell downwards to the ground and the
            waiting dogs. The large jar hit the floor and shattered. Big pieces
            of glass flew everywhere. The dogs were so surprised and frightened
            that they ran from the room. But {stuffy_name_text} was okay and
            hopped up, quick as a flash, and hid behind some boxes.
          </p>
          <p>
            A moment later the Collector rushed into the room to see what
            happened. He called the dogs in and stared at them.
          </p>
          <p>
            “Did you do this?” he asked angrily. “Did you eat that little
            creature? You naughty dogs!”
          </p>
          <p>
            The dogs slowly wandered out of the room with confused and guilty
            faces. The collector cleaned up the broken glass and went to bed.
          </p>
          <p>
            When all was quiet, {stuffy_name_text} came out of{" "}
            {stuffy_gender_possessive} hiding place and climbed up on the shelf.{" "}
            {capitalizeFirstLetter(stuffy_gender_text)} slowly opened the door
            to Bobo’s cage and helped him out.
          </p>
          <p>“Come on Dodo,” {stuffy_gender_text} said.</p>
          <p>“I’m Bobo, not Dodo.”</p>
          <p id="last-paragraph">
            &quot;Right, Jojo&quot;, said {stuffy_name_text}, &quot;let&apos;s
            find the way out!&quot;
          </p>
          <Link to={"/product/collector2"} className="to-be-continued">
            To be continued in Part 2...
          </Link>
        </div>
      </div>
    </>
  );
};

Story.propTypes = {
  kid_name: PropTypes.string,
  kid_gender: PropTypes.string,
  stuffy_name: PropTypes.string,
  stuffy_gender: PropTypes.string,
};

export default Story;
