import * as React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import StoryCard from "../components/story-card";
import Stories from "../story_db.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./library.scss";
import "./page-styles.scss";

const Library = () => {
  const story_data = Stories.data;
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filtered_results = story_data.filter(
    (data) =>
      data.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.descriptive_title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Header />
      <Helmet
        link={[
          {
            rel: "canonical",
            key: "https://www.believeinbedtime.com/library",
            href: "https://www.believeinbedtime.com/library",
          },
        ]}
      >
        <title>Bedtime Stories Online</title>
        <meta
          name="description"
          content="Bedtime stories to read online. Personalized bedtime stories for free."
        />
      </Helmet>
      <div className="main-content">
        <h1 className="library-title">Library</h1>
        <div className="library-page">
          {/* <div className="library-filters">
            <div>age</div>
            <div>length</div>
            <div>experience</div>
          </div> */}
          <div className="library-main">
            <div className="library-input-wrap">
              <FontAwesomeIcon
                icon={faSearch}
                className="library-search-icon"
              />
              <input
                className="book-search-input"
                type="text"
                placeholder="Search"
                onChange={handleChange}
              />
            </div>
            <div className="story-list">
              {filtered_results.map((data, i) => (
                <StoryCard data={data} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Library;
