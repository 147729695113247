import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import StoryHeader from "../components/story-header";

const Story = ({ kid_name, kid_gender, stuffy_name, stuffy_gender }) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const title = "Long Journey, Part 2";
  const author = "Written by Chuck Ludeke, copyright 2021";
  const kid_name_text = capitalizeFirstLetter(kid_name);
  const kid_gender_text =
    kid_gender === "male" ? "he" : kid_gender === "female" ? "she" : "they";
  const kid_gender_possessive =
    kid_gender === "male" ? "his" : kid_gender === "female" ? "her" : "their";
  const kid_gender_3rd =
    kid_gender === "male" ? "him" : kid_gender === "female" ? "her" : "them";

  const stuffy_name_text = capitalizeFirstLetter(stuffy_name);
  const stuffy_gender_text =
    stuffy_gender === "male"
      ? "he"
      : stuffy_gender === "female"
      ? "she"
      : "they";
  const stuffy_gender_possessive =
    stuffy_gender === "male"
      ? "his"
      : stuffy_gender === "female"
      ? "her"
      : "their";
  //   const stuffy_gender_3rd =
  //     stuffy_gender === "male"
  //       ? "him"
  //       : stuffy_gender === "female"
  //       ? "her"
  //       : "them";
  //   const stuffy_self =
  //     stuffy_gender === "male"
  //       ? "himself"
  //       : stuffy_gender === "female"
  //       ? "herself"
  //       : "themselves";

  //   const was_verb_text = stuffy_gender === "nonbinary" ? "were" : "was";

  return (
    <>
      <div>
        <StoryHeader
          title={title}
          author={author}
          background_info={
            <div>
              In this story {kid_name_text} and {stuffy_name_text} are
              continuing their search for Twig. Read{" "}
              <Link to={"/product/longjourney1"}>Part 1</Link> here if you
              haven&apos;t already.
            </div>
          }
          pics={["Twig"]}
        />

        <div className="main-story-wrap">
          <p>
            After their adventure in the Land of Boulders, where they got locked
            in jail, and the Land of Ruins, with the mummy, {kid_name_text} and{" "}
            {stuffy_name_text} came out of the desert. They followed a winding
            road that meandered along the shores of a wide lake, a strange place
            where the sky was pink, the trees were blue, and the lake was white
            as milk.
          </p>
          <p>
            “It even tastes like milk,” {stuffy_name_text} said. And{" "}
            {stuffy_gender_text} filled {stuffy_gender_possessive} canteen.
          </p>
          <p>
            They walked on until they came to three small houses. The first was
            made of straw. A little wolf, dressed in batman pajamas and not much
            taller than {stuffy_name_text}, was standing outside.
          </p>
          <p>
            “How do you like my new house?” he asked. “I made it myself. Now
            I&apos;ll be safe from the big, bad piggy.”
          </p>
          <p>
            “It&apos;s very nice,” {stuffy_name_text} said. But then{" "}
            {stuffy_gender_text} whispered to {kid_name_text}: “It doesn&apos;t
            look very strong.”
          </p>
          <p>
            They came to the next house which was made of sticks. A little wolf,
            dressed in Ironman pajamas was standing outside.
          </p>
          <p>
            “How do you like my new house?” he asked. “I made it myself. Now
            I&apos;ll be safe from the big, bad piggy.”
          </p>
          <p>
            “It&apos;s very nice,” {stuffy_name_text} said. But then{" "}
            {stuffy_gender_text} whispered to {kid_name_text} again: “It
            doesn&apos;t look very strong.”
          </p>
          <p>
            Then they came to a house made out of bricks. A little wolf, dressed
            in Superman pajamas, was standing outside.
          </p>
          <p>
            “Come in, come in!” he said. “You&apos;ll be safe in here. The big,
            bad piggy is coming.”
          </p>
          <p>
            “Well, maybe,” {kid_name_text} said. “But tell me, what is this
            place called?”
          </p>
          <p>
            “Why, it&apos;s Topsy Turvy Land, of course!” said the little wolf.
          </p>
          <p>
            “Hmmmmm,” {kid_name_text} said. “Come on {stuffy_name_text}.”{" "}
            {kid_name_text} walked back to the straw house and asked the first
            wolf if they could stay with him.
          </p>
          <p>“Sure,” he said. “And look! Here comes the big, bad piggy!”</p>
          <p>
            {kid_name_text} and {stuffy_name_text} and the little wolf looked
            out the window. They saw the big, bad piggy standing outside the
            house of bricks. He huffed and he puffed and he blew the house in!
            The little wolf with Superman pajamas ran out the back and joined
            his brother in the house of sticks. But the big, bad piggy stood
            outside the house of sticks and he huffed and he puffed and he blew
            that house in too! The two little wolves ran out the back and joined
            everyone else in the house of straw.
          </p>
          <p>
            Now the big, bad piggy came to the house of straw and said: “Little
            wolf, little wolf, open the door and let me come in!”
          </p>
          <p>“No!” shouted the little wolf.</p>
          <p>
            “Then I&apos;ll huff and I&apos;ll puff and I&apos;ll blow the house
            in!” And so he huffed and he puffed but he could not blow the house
            of straw down. After a while he gave up and went home disappointed.
            The three little wolves cheered and celebrated.
          </p>
          <p>
            {stuffy_name_text} looked up at {kid_name_text} and asked: “
            {kid_name_text}, how did you know that the house of straw would be
            the strongest?”
          </p>
          <p>
            “Because we&apos;re in Topsy Turvy Land where everything is kind of
            opposite what it would normally be.”
          </p>
          <p>
            {kid_name_text} and {stuffy_name_text} walked on through the village
            of Topsy Turvy. It was almost bedtime, but children were walking to
            school with their lunchboxes.
          </p>
          <p>“Why are you going to school?” {stuffy_name_text} asked.</p>
          <p>
            “We always go to school at night in Topsy Turvey Land!” they said.
          </p>
          <p>
            Just then, the ground shook. The villagers all stopped what they
            were doing and listened. The ground shook again and a loud boom,
            boom, boom sound was getting closer. “Oh, no!” the villagers cried.
            “Run, run! Run away everyone! Run for your life!”
          </p>
          <p>“What is it?” {stuffy_name_text} asked.</p>
          <p>
            A little girl with a basket and red cape stopped to say: “It&apos;s
            the Gingerbread Man! He&apos;s a monster! And he&apos;ll gobble you
            up!”
          </p>
          <p>
            {kid_name_text} looked around and saw the head of the Gingerbread
            Man above the house tops! He was huge and coming their way.{" "}
            {kid_name_text} realized that some of the school children were too
            young to run away. {capitalizeFirstLetter(kid_gender_text)} knew{" "}
            {kid_gender_text}
            had to help somehow. {capitalizeFirstLetter(kid_gender_text)}{" "}
            noticed that the lake was near by and had an idea.
          </p>
          <p>
            “Hey! Gingerbread Man!” {kid_gender_text} shouted. “Try to catch me
            if you can!”
          </p>
          <p>
            The Gingerbread Man saw {kid_name_text} and started to chase{" "}
            {kid_gender_3rd}. {kid_name_text} ran to the lake and waded into the
            milk until it was up to {kid_gender_possessive} waist. The
            Gingerbread Man ran into the lake too. “I&apos;ll get you!” he said.
            “And I&apos;ll gobble you up!” But with every step he began to slow
            down. The Gingerbread Man looked down and saw that his feet were
            dissolving in the milk. He took another step toward {kid_name_text},
            but now his legs were turning to mush. He finally fell forward into
            the milk with a big splash. He tried to push himself up, but his
            hands were gone. Soon, all of him had gone, turned to mush in the
            milk!
          </p>
          <p>
            The villagers cheered for {kid_name_text}. “You saved us!” they
            shouted. “You saved us!”
          </p>
          <p>
            “Gee {kid_name_text},” {stuffy_name_text} said, “how did you know
            that would happen?”
          </p>
          <p>
            “Well,” {kid_name_text} said, “haven&apos;t you ever dipped your
            cookie in milk and left it in too long? It always turns to mush.”
          </p>
          <p>
            That night {kid_name_text} and {stuffy_name_text} camped at the edge
            of town. {stuffy_name_text} sat by the campfire and roasted{" "}
            {stuffy_gender_possessive} dinner on a stick. “I don&apos;t think
            we&apos;ll ever find Twig,” {stuffy_gender_text} said.
          </p>
          <p>“All we can do is keep trying,” {kid_name_text} said.</p>
          <p>
            The next morning was very windy. {kid_name_text} and{" "}
            {stuffy_name_text} took the road out of Topsy Turvy and passed a big
            egg-shaped person sitting on a red brick wall.
          </p>
          <p>
            “Good Morning, {kid_name_text} and {stuffy_name_text}!” he said,
            rocking back and forth in the wind. “My name is Humpty Dumpty.”
          </p>
          <p>“How did you know us?” {kid_name_text} asked.</p>
          <p>
            “Well, a little guy named Twig told me that you might come this way
            looking for him. He told me that he&apos;s trying to find his way
            home.”
          </p>
          <p>“Where is he?!” {kid_name_text} asked excitedly.</p>
          <p>
            “I&apos;m sorry to say that I don&apos;t know. He went that way,
            down into the valley, down into the Land of the Jungle,” said
            Humpty. The wind almost blew him off the wall, but he held on.
          </p>
          <p>
            “Thank you Humpty Dumpty,” {kid_name_text} said. “Come on{" "}
            {stuffy_name_text}. We&apos;re off to the jungle!”
          </p>

          <div className="to-be-continued">Part 3 coming soon...</div>
        </div>
      </div>
    </>
  );
};

Story.propTypes = {
  kid_name: PropTypes.string,
  kid_gender: PropTypes.string,
  stuffy_name: PropTypes.string,
  stuffy_gender: PropTypes.string,
};

export default Story;
