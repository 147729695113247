import React from "react";
import { useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const SocialShare = () => {
  let location = useLocation();
  let currentUrl = "https://www.believeinbedtime.com" + location.pathname;

  return (
    <div>
      <FacebookShareButton
        url={currentUrl}
        quote={
          "Believe in Bedtime - Personalized bedtime stories to read online"
        }
        hashtag="#believeinbedtime"
      >
        <FacebookIcon size={36} />
      </FacebookShareButton>

      <TwitterShareButton
        url={currentUrl}
        quote={
          "Believe in Bedtime - Personalized bedtime stories to read online"
        }
        hashtag="#believeinbedtime"
      >
        <TwitterIcon size={36} />
      </TwitterShareButton>

      <WhatsappShareButton
        url={currentUrl}
        quote={
          "Believe in Bedtime - Personalized bedtime stories to read online"
        }
        hashtag="#believeinbedtime"
      >
        <WhatsappIcon size={36} />
      </WhatsappShareButton>
    </div>
  );
};

export default SocialShare;
