import * as React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./page-styles.scss";
import "./trial.scss";

const Trial = () => {
  return (
    <>
      <Header />
      <Helmet
        link={[
          {
            rel: "canonical",
            key: "https://www.believeinbedtime.com/trial/",
            href: "https://www.believeinbedtime.com/trial/",
          },
        ]}
      >
        <title>Free Bedtime Stories to Read Online</title>
        <meta name="description" content="Try free online bedtime stories." />
      </Helmet>
      <div className="main-content trial-page">
        <h1 className="trial-title">TRY ONE MONTH FREE</h1>
        <a href="#" className="trial-button" disabled>
          Try for $0.00
        </a>
        <div className="coming-soon-text">Coming Soon!</div>
        <div className="trial-subtext">
          $3.95 a month after 30 days. Cancel anytime.
        </div>
        <div className="trial-checklist">
          <div className="trial-check-item">
            <FontAwesomeIcon icon={faCheck} className="trial-check-icon" />
            80+ Believe in Bedtime stories
          </div>
          <div className="trial-check-item">
            <FontAwesomeIcon icon={faCheck} className="trial-check-icon" />
            Access anytime, anywhere
          </div>
          <div className="trial-check-item">
            <FontAwesomeIcon icon={faCheck} className="trial-check-icon" />
            Try it free for one month
          </div>
          <div className="trial-check-item">
            <FontAwesomeIcon icon={faCheck} className="trial-check-icon" />
            Cancel online anytime
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Trial;
