import React, { useState } from "react";
import _ from "underscore";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Stars from "../components/stars";
import StoryWrapper from "../stories/story-wrapper";
import Stories from "../story_db.json";
import "./story-product.scss";
import "./sample-story.scss";
import StorySetup from "../components/story-setup";

const SampleStory = () => {
  const all_stories = Stories.data;
  const slug = "collector1"; // change to whatever "sample" should be
  const story = _.findWhere(all_stories, { slug: slug }) || {};

  const [kid_name, setKidName] = useState();
  const [kid_gender, setKidGender] = useState();

  const [stuffy_name, setStuffyName] = useState();
  const [stuffy_gender, setStuffyGender] = useState();

  // options are: step1, step2, story
  const [current_step, setCurrentStep] = useState("step1");

  const StoryInfo = () => {
    return (
      <div className="story-top">
        <Helmet
          link={[
            {
              rel: "canonical",
              key: "https://www.believeinbedtime.com/sample-story",
              href: "https://www.believeinbedtime.com/sample-story",
            },
          ]}
        >
          <title>Try a Personalized Bedtime Story</title>
          <meta
            name="description"
            content="Sample a bedtime story, personalized for your child, to read online."
          />
        </Helmet>
        <h1 className="story-title">{story.title || ""}</h1>
        {
          /* intro here is different because this is the sample */
          <div className="sample-story-intro">
            In this story your child&apos;s favorite stuffed animal (or toy) has
            been captured by the Collector! Your child will go on an exciting
            adventure to rescue them. Just enter their information in two easy
            steps and the story will be ready instantly.
          </div>
        }
        <Stars star_number={story.review_average} />
        <div>Length: {story.length} min</div>
        <div>Ages: 4-8</div>
        <div className="story-product-info">{story.product_info}</div>
      </div>
    );
  };

  const StoryBody = () => {
    switch (current_step) {
      case "step1":
        return (
          <>
            {StoryInfo()}
            <StorySetup
              key="kid"
              input_label="Child's Name"
              step={1}
              name={kid_name}
              setName={setKidName}
              gender={kid_gender}
              setGender={setKidGender}
              slug={slug}
              changeStep={() => setCurrentStep("step2")}
            />
          </>
        );
      case "step2":
        return (
          <>
            {StoryInfo()}
            <StorySetup
              key="stuffy"
              input_label="Stuffed Animal or Favorite Toy's Name"
              step={2}
              name={stuffy_name}
              setName={setStuffyName}
              gender={stuffy_gender}
              setGender={setStuffyGender}
              slug={slug}
              changeStep={() => setCurrentStep("story")}
            />
          </>
        );
      case "story":
        //redirect in future to hidden url??
        return (
          <StoryWrapper
            slug={slug}
            kid_name={kid_name}
            kid_gender={kid_gender}
            stuffy_name={stuffy_name}
            stuffy_gender={stuffy_gender}
          />
        );
      default:
        return "foo";
    }
  };

  // return 401 if no slug
  return (
    <>
      <Header />
      <div className="main-content story-page">
        {!_.isEmpty(story) ? StoryBody() : <div>Story not Found</div>}
      </div>
    </>
  );
};

export default SampleStory;
