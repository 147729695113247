import React from "react";
import PropTypes from "prop-types";
import StoryHeader from "../components/story-header";

const Story = ({ kid_name, kid_gender, stuffy_name, stuffy_gender }) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const title = "Mr. Wiggleworm";
  const author = "Written by Chuck Ludeke, copyright 2021";
  const kid_name_text = capitalizeFirstLetter(kid_name);
  const kid_gender_text =
    kid_gender === "male" ? "he" : kid_gender === "female" ? "she" : "they";
  const kid_gender_possessive =
    kid_gender === "male" ? "his" : kid_gender === "female" ? "her" : "their";
  const kid_gender_3rd =
    kid_gender === "male" ? "him" : kid_gender === "female" ? "her" : "them";
  const kid_self =
    kid_gender === "male"
      ? "himself"
      : kid_gender === "female"
      ? "herself"
      : "themselves";

  const stuffy_name_text = capitalizeFirstLetter(stuffy_name);
  const stuffy_gender_text =
    stuffy_gender === "male"
      ? "he"
      : stuffy_gender === "female"
      ? "she"
      : "they";
  const stuffy_gender_possessive =
    stuffy_gender === "male"
      ? "his"
      : stuffy_gender === "female"
      ? "her"
      : "their";
  const stuffy_gender_3rd =
    stuffy_gender === "male"
      ? "him"
      : stuffy_gender === "female"
      ? "her"
      : "them";

  return (
    <div>
      <StoryHeader
        title={title}
        author={author}
        background_info={
          <div>
            In this story, {kid_name_text} and {stuffy_name_text} live in a
            little house in the woods with their other animal friends, including
            Twig and Freddy the Fox. Nearby the cabin is a beautiful lake.
          </div>
        }
        pics={["Twig", "Freddy", "Lake", "Cabin"]}
      />

      <div className="main-story-wrap">
        <p>
          <span className="freddy-font">“I’m going fishing!”</span> Freddy the
          fox announced.
          <span className="freddy-font">“Who wants to go with me?”</span>
        </p>
        <p>
          <span className="twig-font">“I will,”</span> said Twig.
        </p>
        <p>
          <span className="stuffy-font">“Me too,”</span> said {stuffy_name_text}
          ,{" "}
          <span className="stuffy-font">
            &quot;But not to fish. I&apos;ll just read my book.&quot;
          </span>
        </p>
        <p>
          When they got down to the lake, Freddy started digging in the mud,
          looking for worms. {stuffy_name_text} and Twig got into the little
          rowboat at the end of the dock.
        </p>
        <p>
          <span className="twig-font">“Where are we going to go?”</span> Twig
          asked.
        </p>
        <p>
          <span className="stuffy-font">“No where,”</span> {stuffy_name_text}{" "}
          said, opening {stuffy_gender_possessive} book.{" "}
          <span className="stuffy-font">
            “I just like sitting in the boat.”
          </span>
        </p>
        <p>
          Twig found a little pan in the boat and started calling out:{" "}
          <span className="twig-font">“Here fishy, fishy. Here fishy!”</span> He
          held the pan out over the water.{" "}
          <span className="twig-font">“Come on fishy, here fishy.”</span>
        </p>
        <p>
          {stuffy_name_text} looked up from {stuffy_gender_possessive} book and
          asked,{" "}
          <span className="stuffy-font">&quot;What are you doing Twig?”</span>
        </p>
        <p>
          <span className="twig-font">“I’m fishing,”</span> Twig said.
        </p>
        <p>
          <span className="stuffy-font">“You can’t just call them,”</span>{" "}
          {stuffy_name_text} said.{" "}
          <span className="stuffy-font">
            “You have to put a worm on a hook and catch them!”
          </span>
        </p>
        <p>
          <span className="twig-font">“But worms are icky.”</span>
        </p>
        <p>
          Just then {stuffy_name_text} saw what looked like a big green rock in
          the middle of the lake. Suddenly, {stuffy_gender_text} realized that
          the big green rock was a head with big green eyes and it was looking
          at {stuffy_gender_3rd}! Then it quickly disappeared beneath the
          surface of the lake.
        </p>
        <p>
          <span className="stuffy-font">“Oh my!”</span> {stuffy_name_text} said.{" "}
          <span className="stuffy-font">
            “I think I just saw a monster. Right out there in the middle of the
            lake!”
          </span>
        </p>
        <p>
          <span className="twig-font">“I didn’t see anything,”</span> Twig said.{" "}
          <span className="twig-font">
            “Maybe it was just your imagination.”
          </span>
        </p>
        <p>
          {stuffy_name_text} shrugged and started reading again. Twig set the
          pan down and started playing with a rope that was tied to the dock.
        </p>
        <div className="story-space"></div>
        <p>
          Meanwhile, Freddy the fox was busy looking for worms and didn’t see a
          stranger approaching him. He was so startled he almost jumped out of
          his shoes!
        </p>
        <p>
          He saw a tall man dressed in fisherman’s clothes and boots, but whose
          face and hands were made of hundreds of living, wiggly, slimy worms.{" "}
          <span className="freddy-font">“I think it&apos;s a monster,”</span>{" "}
          Freddy whispered to himself.
        </p>
        <p>
          <span className="wiggleworm-font">“Good morning,”</span> the stranger
          said.{" "}
          <span className="wiggleworm-font">
            “My name is Mr. Wiggleworm. What are you doing?”
          </span>
        </p>
        <p>
          <span className="freddy-font">“Looking for… uh … worms,”</span> Freddy
          said quietly.
        </p>
        <p>
          <span className="wiggleworm-font">
            “Oh, so you’re going fishing just like me,”
          </span>{" "}
          Mr. Wiggleworm said. He was holding a fishing pole.{" "}
          <span className="wiggleworm-font">“I use worms for bait too.”</span>{" "}
          And he plucked a wiggly worm off his chin and stuck it on his hook.{" "}
          <span className="wiggleworm-font">
            “But you know what? I’m hungry. I’ve got to snack on something.”
          </span>{" "}
          And he was looking right at Freddy.
        </p>
        <p>
          Freddy took a step back nervously. But Mr. Wiggleworm opened a small
          brown bag and took out a sandwich: two pieces of moldy white bread
          with a mound of little green worms in between.{" "}
          <span className="wiggleworm-font">“Yum yum, my favorite!”</span> he
          said.{" "}
          <span className="wiggleworm-font">“Would you like a bite?”</span> He
          held out his sandwich to Freddy and some of the little green worms
          fell to the ground and started crawling.
        </p>
        <p>
          <span className="freddy-font">“Um, no thanks,”</span> Freddy said.{" "}
          <span className="freddy-font">
            “But it was nice to meet you, have a nice day.”
          </span>
        </p>
        <p className="story-space"></p>
        <p>
          Back at the boat, Twig began to realize that something wasn’t right.{" "}
          <span className="twig-font">“{stuffy_name_text},”</span> he said,{" "}
          <span className="twig-font">“where are we going?”</span>
        </p>
        <p>
          <span className="stuffy-font">
            “I already told you; we’re not going anywhere,”
          </span>{" "}
          said {stuffy_name_text}. However, {stuffy_name_text} had{" "}
          {stuffy_gender_possessive} nose in the book and didn’t look up.
        </p>
        <p>
          <span className="twig-font">“But look!!”</span> Twig said.
        </p>
        <p>
          When {stuffy_name_text} finally looked up {stuffy_gender_text}, was
          shocked to see that they were floating in the middle of the lake.{" "}
          <span className="stuffy-font">“Oh my gosh!”</span>{" "}
          {stuffy_gender_text} said.{" "}
          <span className="stuffy-font">
            “How did this happen? What happened to the rope that held the boat
            to the dock?”
          </span>
        </p>
        <p>
          <span className="twig-font">“You mean this one?”</span> Twig asked,
          holding the rope in his hands.{" "}
          <span className="twig-font">“...Sorry”</span>
        </p>
        <p>
          {stuffy_name_text} saw Freddy on the shore and called out to him.{" "}
          <span className="stuffy-font">“Help!”</span> {stuffy_gender_text}{" "}
          cried. <span className="stuffy-font">“Go get {kid_name_text}!”</span>
        </p>
        <p>
          Freddy ran and got {kid_name_text} and they stood on the dock.{" "}
          {kid_name_text} called out:{" "}
          <span className="kid-font">“Use the oar!”</span>
        </p>
        <p>
          <span className="stuffy-font">“But I don’t know how,”</span>{" "}
          {stuffy_name_text} said.
        </p>
        <p>
          <span className="kid-font">
            “Put it in the water and paddle your way back!”
          </span>{" "}
          {kid_name_text} said.
        </p>
        <p>
          <span className="twig-font">“I can do it!”</span> Twig said. He picked
          up the oar and threw it in the water.
        </p>
        <p>
          <span className="stuffy-font">“That’s not what he meant!”</span>{" "}
          {stuffy_name_text} cried.{" "}
          <span className="stuffy-font">
            “You’re supposed to hold on to it!”
          </span>
        </p>
        <p>
          Now the oar started to float away from the boat, so {stuffy_name_text}{" "}
          leaned over and tried to grab it, but {stuffy_gender_text} lost{" "}
          {stuffy_gender_possessive} balance and fell in with a big splash.{" "}
          <span className="stuffy-font">“Help!”</span> {stuffy_gender_text}{" "}
          cried. {capitalizeFirstLetter(stuffy_gender_text)} grabbed onto the
          oar and tried to catch {stuffy_gender_possessive} breath.
        </p>
        <p>
          Twig stood up on one of the seats in the little boat and jumped into
          the water. He floated over to {stuffy_name_text} and held on to the
          oar with {stuffy_gender_3rd}.
        </p>
        <p>
          <span className="twig-font">“Twig, why did you jump?”</span>{" "}
          {stuffy_name_text} asked.
        </p>
        <p>
          <span className="twig-font">
            “Because I didn&apos;t want you to be all alone in the water.&quot;
          </span>
        </p>
        <p>
          {kid_name_text} realized {kid_gender_text} had to do something, so{" "}
          {kid_gender_text} jumped in the lake and swam toward the boat. After a
          few minutes {kid_gender_text} reached {stuffy_name_text} and Twig and
          boosted them back up into the boat. Then {kid_gender_text} tossed the
          oar back into the boat and tried to pull {kid_self} up.
        </p>
        <p>
          Back at the dock, Freddy started shouting:{" "}
          <span className="freddy-font">“Look out! Look out!”</span>
        </p>
        <p>
          {kid_name_text} turned around in the water and saw a monstrous green
          serpent head moving through the water, straight toward{" "}
          {kid_gender_3rd}! It had enormous eyes and a wide mouth filled with
          sharp teeth. {kid_name_text} kicked {kid_gender_possessive} feet and
          pulled {kid_self} up into the boat just in time.{" "}
          {capitalizeFirstLetter(kid_gender_text)} grabbed the oar and held it
          over {kid_gender_possessive} head like a club, but the monster dipped
          back down under the surface and all was momentarily quiet.{" "}
          {kid_name_text} started to row them back toward shore.
        </p>
        <p>
          Back on the dock, Mr. Wiggleworm had come running when he heard all
          the shouting. He stood watching with Freddy.
        </p>
        <p>
          <span className="freddy-font">“What was that thing?”</span> Freddy
          asked.
        </p>
        <p>
          <span className="wiggleworm-font">“That’s Old Greenie,”</span> Mr.
          Wiggleworm said.{" "}
          <span className="wiggleworm-font">
            “He’s the monster that lives at the bottom of the lake. He only
            comes up when he gets hungry.”
          </span>
        </p>
        <p>
          <span className="freddy-font">“Look!”</span> Freddy cried. They could
          see something big swimming behind the boat. Suddenly the monster rose
          up and grabbed the stern of the boat in its jaws. It shook the little
          boat so hard that {stuffy_name_text} and Twig were tossed overboard.
        </p>
        <p>
          <span className="twig-font">“Uh oh,”</span> Twig said,{" "}
          <span className="twig-font">“...we&apos;re in the water again”</span>
        </p>
        <p>
          {kid_name_text} lifted the oar and whacked Old Greenie on the head as
          hard as {kid_gender_text} could. The monster let go of the boat, and
          started to swim around to gobble up {stuffy_name_text} and Twig. But
          just then Mr. Wiggleworm ran out part way into the water, waving his
          arms and shouting Old Greenie’s name. The monster turned, swam
          straight at Mr. Wiggleworm and swallowed him in one bite. Then it
          dived down into the water, returning to the bottom of the lake. Only
          Mr. Wiggleworm’s cap floated on the water.
        </p>
        <p>
          {kid_name_text} picked {stuffy_name_text} and Twig out of the water
          and rowed them back to the dock. Everyone was tired and sad. They
          walked slowly back up to the house.
        </p>
        <p>
          <span className="stuffy-font">&quot;Poor Mr. Gigglefirm,&quot;</span>{" "}
          {stuffy_name_text} said.
        </p>
        <p>
          <span className="freddy-font">
            &quot;It&apos;s not Gigglefirm, it&apos;s Wiggleworm!&quot;
          </span>{" "}
          Freddy said.
        </p>
        <p>
          <span className="stuffy-font">
            &quot;Oh, that&apos;s right,&quot;
          </span>{" "}
          said {stuffy_name_text},{" "}
          <span className="stuffy-font">&quot;Poor Mr. Biggleberm.&quot;</span>
        </p>
        <p>
          A week later {stuffy_name_text} and Freddy were playing down by the
          lake when they saw Mr. Wiggleworm!{" "}
          <span className="stuffy-font">&quot;It must be a ghost,&quot;</span>{" "}
          {stuffy_name_text} said. But Mr. Wiggleworm said{" "}
          <span className="wiggleworm-font">&quot;Good morning&quot;</span> to
          them and seemed just as friendly as before.
        </p>
        <p>
          <span className="stuffy-font">
            &quot;But we saw you get gobbled up by Old Greenie!&quot;
          </span>{" "}
          {stuffy_name_text} said.
        </p>
        <p>
          <span className="wiggleworm-font">
            &quot;Well, that&apos;s true,&quot;
          </span>{" "}
          Mr. Wiggleworm said,{" "}
          <span className="wiggleworm-font">
            &quot;But, since I&apos;m made of worms, after a few days all my
            worms wiggled back together again and I swam out of the lake. Would
            you like a bite of my sandwich?&quot;
          </span>{" "}
          He showed them his big moldy sandwich with lots of little green worms
          in the middle.
        </p>
        <p>
          <span className="stuffy-font">&quot;Uh, no thanks,&quot;</span>{" "}
          {stuffy_name_text} said.
        </p>
        <p id="last-paragraph">
          One night when the animals were getting ready for bed, they asked{" "}
          {kid_name_text} if Old Greenie could come out of the lake and get
          them. <span className="kid-font">&quot;No,&quot;</span>{" "}
          {kid_name_text} explained.{" "}
          <span className="kid-font">
            &quot;Lake monsters can&apos;t walk on land. Old Greenie
            doesn&apos;t have legs.&quot;
          </span>{" "}
          But {kid_name_text} thought to {kid_self}: I sure hope it
          doesn&apos;t!
        </p>
        <p className="the-end-text">The End</p>
      </div>
    </div>
  );
};

Story.propTypes = {
  kid_name: PropTypes.string,
  kid_gender: PropTypes.string,
  stuffy_name: PropTypes.string,
  stuffy_gender: PropTypes.string,
};

export default Story;
