import * as React from "react";
import PropTypes from "prop-types";

const GenderRadioButtons = ({ styleName, gender, setGender }) => {
  return (
    <>
      <label className={styleName}>
        <input
          type="radio"
          value="female"
          checked={gender === "female"}
          onClick={() => setGender("female")}
        />
        <span>She/Her</span>
      </label>
      <label className={styleName}>
        <input
          type="radio"
          value="male"
          checked={gender === "male"}
          onClick={() => setGender("male")}
        />
        <span>He/Him</span>
      </label>
      <label className={styleName}>
        <input
          type="radio"
          value="nonbinary"
          checked={gender === "nonbinary"}
          onClick={() => setGender("nonbinary")}
        />
        <span>They/Them</span>
      </label>
    </>
  );
};

GenderRadioButtons.propTypes = {
  gender: PropTypes.string,
  setGender: PropTypes.func,
  styleName: PropTypes.string,
};

export default GenderRadioButtons;
