import * as React from "react";
import Header from "../components/header";
import { Helmet } from "react-helmet";
import "./page-styles.scss";
import "./not-found.scss";

const NotFound = () => {
  return (
    <div>
      <Header />
      <Helmet>
        <title>Believe in Bedtime - Page Not Found</title>
        <meta
          name="description"
          content="Believe in Bedtime - Try an online bedtime story."
        />
      </Helmet>
      <div className="main-content">
        <div className="not-found">Page Not Found</div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default NotFound;
