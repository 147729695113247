import * as React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import "./page-styles.scss";
import "./about.scss";

const About = () => {
  return (
    <>
      <Header />
      <Helmet
        link={[
          {
            rel: "canonical",
            key: "https://www.believeinbedtime.com/about",
            href: "https://www.believeinbedtime.com/about",
          },
        ]}
      >
        <title>About Believe in Bedtime</title>
        <meta
          name="description"
          content="Magical bedtime stories will encourage imagination and a love for books."
        />
      </Helmet>
      <div className="main-content about-page">
        <h1 className="page-title">About</h1>
        {/* When I was a child my dad made up bedtime stories using my favorite
        stuffed animals as the main characters. My siblings and I loved seeing
        what silly adventures our toys would get into. The stories created a
        magical world for our imaginations to grow in. */}
        <p>
          Here at Believe in Bedtime, we want to bring the joy of storytelling
          to your children as simply and effortlessly as possible. Perhaps
          you’re tired of the same books, or away from home, and just want to
          read a quick story from your phone.
        </p>
        <p>
          By having your child be the main character in the story, we believe
          they will become more engaged in the story and develop their
          imagination. We hope these stories help create bonding bedtime
          experience, and encourage a love of stories and books that lasts a
          lifetime.
        </p>

        <a className="about-page--button" href="/library">
          Start your own adventure now
        </a>
      </div>
      <Footer />
    </>
  );
};

export default About;
