import * as React from "react";
import _ from "underscore";
import Header from "../components/header";
import Footer from "../components/footer";
import HeaderImage from "../images/home.jpg";
import Subscribe from "../components/subscribe";
import StoryCard from "../components/story-card";
import Stories from "../story_db.json";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faMobileAlt,
  faChild,
  faGrinHearts,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";

import "./home.scss";

const Home = () => {
  const story_data = Stories.data;
  const popular_stories = _.sortBy(story_data, (data) =>
    parseInt(data.popularity, 10)
  );
  const top_4 = popular_stories.slice(0, 4);

  return (
    <>
      <Header />
      <Helmet
        link={[
          {
            rel: "canonical",
            key: "https://www.believeinbedtime.com/",
            href: "https://www.believeinbedtime.com/",
          },
        ]}
      >
        <title>Believe in Bedtime - Bedtime Stories for Kids</title>
        <meta
          name="description"
          content="Bedtime Stories to Read Online. Make bedtime better with a personalized bedtime story."
        />
      </Helmet>

      <div className="main-banner-content">
        <img
          src={HeaderImage}
          className="home-image"
          alt="stuffed animals waiting for a bedtime story"
        />
        <div className="main-banner-text">
          <h1 className="home-header-text">Bedtime Stories for Kids</h1>
          <div className="home-image-sub-text">
            Let your child be the hero in their own bedtime adventure
          </div>
          <Link to="/library/" className="main-button">
            Choose a Story
          </Link>
          <Link to="/sample-story" className="home-image-sub-link">
            or jump right in
          </Link>
        </div>
      </div>

      <div className="popular-story-banner">
        {top_4.map((data, i) => (
          <StoryCard data={data} key={i} />
        ))}
      </div>

      <div className="home-section">
        <div className="info-block">
          <FontAwesomeIcon icon={faBolt} className="info-block--icon" />
          <div className="info-block--title">Instant</div>
          <p className="info-block--text">
            Stories are ready in minutes. Just enter one or two simple form
            fields and your child&apos;s bedtime story is ready to go!
          </p>
        </div>

        <div className="info-block">
          <FontAwesomeIcon icon={faUserEdit} className="info-block--icon" />
          <div className="info-block--title">Personalized</div>
          <p className="info-block--text">
            Your child and their favorite toy or stuffed animal are the stars of
            the story.
          </p>
        </div>

        <div className="info-block">
          <FontAwesomeIcon icon={faMobileAlt} className="info-block--icon" />
          <div className="info-block--title">Mobile</div>
          <p className="info-block--text">
            Read from your phone whenever and wherever. No night lights
            required.
          </p>
        </div>

        <div className="info-block">
          <FontAwesomeIcon icon={faChild} className="info-block--icon" />
          <div className="info-block--title">Appropriate</div>
          <p className="info-block--text">
            Appropriate for any child approximately ages 3-8. With additional
            ranges coming soon!
          </p>
        </div>

        <div className="info-block">
          <FontAwesomeIcon icon={faGrinHearts} className="info-block--icon" />
          <div className="info-block--title">Exciting</div>
          <p className="info-block--text">
            Develop a love for stories and books in your child.
          </p>
        </div>
      </div>

      <Subscribe />
      <Footer />
    </>
  );
};

export default Home;
