import * as React from "react";
import PropTypes from "prop-types";

const NameInput = ({ styleName, label, name, updateName }) => {
  return (
    <>
      <label className={styleName}>
        {label}
        <input
          type="text"
          value={name}
          onChange={(e) => updateName(e.target.value)}
        />
      </label>
    </>
  );
};

NameInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  updateName: PropTypes.func,
  styleName: PropTypes.string,
};

export default NameInput;
