import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import StoryHeader from "../components/story-header";

const Story = ({ kid_name, kid_gender, stuffy_name, stuffy_gender }) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const title = "Long Journey, Part 1";
  const author = "Written by Chuck Ludeke, copyright 2021";
  const kid_name_text = capitalizeFirstLetter(kid_name);
  const kid_gender_text =
    kid_gender === "male" ? "he" : kid_gender === "female" ? "she" : "they";
  const kid_gender_possessive =
    kid_gender === "male" ? "his" : kid_gender === "female" ? "her" : "their";
  // const kid_gender_3rd = kid_gender === "male" ? "him" : (kid_gender === "female" ? "her" : "them")

  const stuffy_name_text = capitalizeFirstLetter(stuffy_name);
  const stuffy_gender_text =
    stuffy_gender === "male"
      ? "he"
      : stuffy_gender === "female"
      ? "she"
      : "they";
  const stuffy_gender_possessive =
    stuffy_gender === "male"
      ? "his"
      : stuffy_gender === "female"
      ? "her"
      : "their";
  const stuffy_gender_3rd =
    stuffy_gender === "male"
      ? "him"
      : stuffy_gender === "female"
      ? "her"
      : "them";
  const stuffy_self =
    stuffy_gender === "male"
      ? "himself"
      : stuffy_gender === "female"
      ? "herself"
      : "themselves";

  //   const was_verb_text = stuffy_gender === "nonbinary" ? "were" : "was";

  return (
    <>
      <div>
        <StoryHeader
          title={title}
          author={author}
          background_info={
            <div>
              In this story {kid_name_text} and {stuffy_name_text} live in a
              little house in the woods with their other animal friends,
              including Twig, a baby tree Ent.
            </div>
          }
          pics={["Twig", "Cabin", "Woods"]}
        />

        <div className="main-story-wrap">
          <p>
            {kid_name_text} made a sign with a large piece of white paper and
            some crayons, then tacked it up on the wall.
          </p>
          <p>“What does it say?” Twig asked.</p>
          <p>
            “It says: Happy Birthday Twig!” {kid_name_text} pointed to the
            letters and explained that Twig started with the letter T.
          </p>
          <p>
            Twig traced the letter with his finger and said: “T is for Twig! T
            is for Twig!” He went outside and scratched a T into the dirt. Then
            he saw {stuffy_name_text} blowing up some big balloons.{" "}
            {capitalizeFirstLetter(stuffy_gender_text)} tied strings to five of
            them and held on tight so they didn&apos;t get away. But{" "}
            {stuffy_name_text} got tired of holding the balloons so{" "}
            {stuffy_gender_text} gave them to Twig. “Hold these, Twig,”{" "}
            {stuffy_gender_text} said. “They&apos;re for your party.”
          </p>
          <p>
            “Okay,” Twig said. But the second he took them he started to float
            upward and {stuffy_name_text} had to grab his leg.
          </p>
          <p>“Whoa!” {stuffy_name_text} said. “You might fly away!”</p>
          <p>“I don&apos;t want to fly away,” said Twig.</p>
          <p>
            {stuffy_name_text} thought it would be funny to tease Twig for a
            minute, so {stuffy_gender_text} let go of Twig&apos;s leg and Twig
            floated up into the air. Twig was scared and cried help, and{" "}
            {stuffy_name_text} laughed. “Don&apos;t worry,” {stuffy_name_text}{" "}
            said, “I&apos;ll get you.” But when {stuffy_gender_text} jumped up
            to grab Twig it was too late. Twig was out of reach.
          </p>
          <p>
            “Oh, no!” {stuffy_name_text} cried. “{kid_name_text}, help!”
          </p>
          <p>
            {kid_name_text} and the other animals ran out and looked up. Twig
            was floating up by the tree tops and continuing to go up and up.{" "}
            {stuffy_name_text} sat down and started to cry. “It&apos;s my
            fault,” {stuffy_gender_text} said.{" "}
            {capitalizeFirstLetter(stuffy_gender_text)} told {kid_name_text}{" "}
            what happened.
          </p>
          <p>
            In a tiny voice from far above, Twig called down: “What should I
            do?”
          </p>
          <p>
            “Hang on!” {kid_name_text} shouted. “I&apos;ll come after you!” Now
            Twig was only a speck in the sky being carried out over the forest
            by the wind. Soon they couldn&apos;t see him at all.
          </p>
          <p>
            All the animals looked at {kid_name_text} and waited for{" "}
            {kid_gender_possessive} plan. After a few minutes {kid_gender_text}{" "}
            said: “Freddy the fox will stay here at the house and take care of
            everyone. Teddy bear, you do the cooking. Rocky, remember to lock
            the door at night. And {stuffy_name_text}, stop crying. You&apos;re
            coming with me. Pack your backpack, bring a sleeping bag, and fill
            your canteen. We might be gone a long time.”
          </p>
          <p>
            Freddy raised his hand and asked: “{kid_name_text}, can we have the
            party now anyway?”
          </p>
          <p>
            “No, you&apos;ll have to wait for all of us to get back home,” said{" "}
            {kid_name_text}.
          </p>
          <p>
            When they were ready, {kid_name_text} and {stuffy_name_text} said
            goodbye to everyone and set off down the trail. “How do we know
            where to go?” {stuffy_name_text} asked. “We don&apos;t know
            exactly,” {kid_name_text} said. “But the wind was blowing south
            today so we will head south. If we&apos;re lucky we&apos;ll
            eventually find Twig.”
          </p>

          <p>
            {kid_name_text} and {stuffy_name_text} hiked through the Great
            Southern Forest until they came to a wide river. {kid_name_text}{" "}
            took out {kid_gender_possessive} compass and waited for the needle
            to point north. Then {kid_gender_text} could see that to keep going
            south they would have to cross the river. “How will we cross?”{" "}
            {stuffy_name_text} asked. “It&apos;s too far to swim.”{" "}
            {kid_name_text} took a hand axe from {kid_gender_possessive}{" "}
            backpack and said: “In the morning we&apos;ll make a raft.”
          </p>
          <p>
            They camped by the river that night, with a small campfire to keep
            the bears and wolves away. And in the morning {stuffy_name_text}{" "}
            picked raspberries while {kid_name_text} chopped down a few small
            trees, trimmed the branches off, and lashed the logs together with
            rope. The raft was about the size of a table top. {kid_name_text}{" "}
            also fashioned a small paddle. When all was ready they pushed the
            raft into the water and placed their backpacks in the middle. Then
            they hopped on and pushed off and the raft floated slowly across the
            wide river as {kid_name_text} paddled. An eagle soared far overhead
            and {stuffy_name_text} wished they could ask it if it&apos;d seen
            Twig.
          </p>
          <p>
            On the other side of the river, they pulled the raft out of the
            water and left it on a big rock. Then they climbed a steep trail
            that led up to the top of a giant rock cliff. Looking about they saw
            that they had left the forest and were now entering the Land of
            Boulders. Giant rock formations were sprinkled across the flat land
            with hardly a tree in sight. They walked until evening when they
            came to a small building by the side of a road. A little pony out
            front was munching on grass. The sign on the building said: JAIL.
            The second floor window had bars and they saw an unhappy face
            looking out through the bars.
          </p>
          <p>
            A gruff-looking person with a club came outside. “Hello there,” he
            said. “I&apos;m Sheriff Bulldog. Where are you headed?”
          </p>
          <p>
            “We&apos;re traveling south, in search of a friend,” {kid_name_text}{" "}
            said.
          </p>
          <p>
            “Good, good. Come with me,” the Sheriff said. He led {kid_name_text}{" "}
            and {stuffy_name_text} upstairs and locked them in the jail.
          </p>
          <p>
            “Hey! What are you doing?” {kid_name_text} said. “We didn&apos;t go
            anything wrong!”
          </p>
          <p>
            “Well,” the sheriff said, “I&apos;m from the land of Topsy-Turvy. I
            only lock up good people in my jail.” And he went back downstairs.
          </p>
          <p>
            {stuffy_name_text} put {stuffy_gender_possessive} hands on the bars
            and shouted: “You&apos;re crazy!”
          </p>
          <p>
            Now the other person in the jail cell was an elf with a pointed cap
            and slightly pointy ears and a big smile. “Hello, my friends,” he
            said. “My name is Anno. I was simply traveling by when I got caught
            and thrown in jail too.” Anno was working on a drawing. “I like to
            draw when I&apos;m not traveling,” he explained.
          </p>
          <p>
            That gave {kid_name_text} an idea.{" "}
            {capitalizeFirstLetter(kid_gender_text)} asked Anno to draw a
            picture of the three of them. Then {kid_name_text} started writing
            in big letters to make it a poster. When it was done,{" "}
            {kid_gender_text} slipped it through the bars and it floated down to
            the ground in front of the jail. Then they all went to sleep.
          </p>
          <p>
            In the morning, Sheriff Bulldog came out and picked up the poster.
            It said: WANTED By POLICE; THREE BAD FRIENDS (the faces of Anno,{" "}
            {kid_name_text}, and {stuffy_name_text}). There was a long list of
            crimes, which included changing a wizard into a toad, crashing a
            spaceship from Pluto, and throwing pies at a party for Mayor Grumpo.
            When Sheriff Bulldog read this he was mortified. “That&apos;s
            terrible!” he said. He marched upstairs and opened the jail cell. “I
            just found out that you all are really bad!” he exclaimed. “Leave
            now! I won&apos;t have bad guys in my jail!”
          </p>
          <p>
            Anno, {kid_name_text}, and {stuffy_name_text} walked out. Anno got
            on his trusty pony and prepared to leave. “Two days ago,” he said,
            “I heard about a strange bird that was seen flying over the Land of
            Boulders. It was strange because it looked like a stick.”
          </p>
          <p>
            “That must be Twig!” {kid_name_text} said. “Anno, do you know what
            lies further south of here?”
          </p>
          <p>
            “If you keep south, you&apos;ll reach the desert and the Land of
            Ruins,” Anno said. “Good luck! I hope we meet again someday!”
          </p>
          <p>
            {kid_name_text} and {stuffy_name_text} said goodbye to Anno the elf
            and hiked south until they came to a little creek at the edge of the
            desert. They stopped to rest and filled their canteens with water
            before marching out into a landscape that was only sand for as far
            as they could see.
          </p>
          <p>
            By dusk they had reached the Land of Ruins, a place that had once
            been a great city, but was now empty. No one lived there. They saw
            enormous statues made of stone, some bigger than houses. Each one
            had the body of a lion, but the head of a man. They saw many tall
            stone columns; most were broken and half buried in the sand. And
            here and there they saw stone steps descending into the ground, into
            long dark corridors.
          </p>
          <p>
            “Gee,” {stuffy_name_text} said, “I wonder where the steps lead.”
          </p>
          <p>
            “Probably into a tomb, where some ancient king was buried with his
            treasure,” {kid_name_text} said.
          </p>
          <p>
            “Really?” {stuffy_name_text} asked. “Maybe we should go down there
            and search for treasure!”
          </p>
          <p>
            “Not me,” {kid_name_text} said. “I wouldn&apos;t want to wake a
            mummy!”
          </p>
          <p>
            The sun had gone down and the stars came out. {kid_name_text}{" "}
            snacked on fruit and crackers and unrolled {kid_gender_possessive}{" "}
            sleeping bag on the warm sand.{" "}
            {capitalizeFirstLetter(kid_gender_text)} looked up at the stars and
            wondered how they would ever find Twig in this vast desert. Soon{" "}
            {kid_gender_text} was fast asleep. But {stuffy_name_text}{" "}
            couldn&apos;t fall asleep.{" "}
            {capitalizeFirstLetter(stuffy_gender_text)} kept thinking about
            treasure. So {stuffy_gender_text} took a little flashlight out of{" "}
            {stuffy_gender_possessive} backpack and wandered toward the big
            statues. {capitalizeFirstLetter(stuffy_gender_text)} hardly made a
            sound as {stuffy_gender_text} tip-toed down the stone steps into the
            narrow dark corridor. There were ancient writings on the walls, but{" "}
            {stuffy_name_text} couldn&apos;t read them.{" "}
            {capitalizeFirstLetter(stuffy_gender_text)} would have been
            surprised to know that it said:{" "}
            <i>
              Whosoever disturbs my tomb, they shall suffer a terrible doom.
            </i>
          </p>
          <p>
            At the end of the passageway was a room with a lot of old dusty
            stuff leaning against the walls and a large stone sarcophagus in the
            center. The top was missing so {stuffy_name_text} pulled{" "}
            {stuffy_self} up and peaked in. Fortunately, there was no mummy in
            it. Maybe the mummy was taken away to a museum, {stuffy_name_text}{" "}
            thought. But then {stuffy_gender_text} noticed something sparkling
            in the bottom of the sarcophagus. {stuffy_name_text} hopped in and
            found a pile of gold necklaces, bracelets, and rings.{" "}
            {capitalizeFirstLetter(stuffy_gender_text)} stuffed{" "}
            {stuffy_gender_possessive} jacket pockets full of treasure.{" "}
            {stuffy_name_text} was so busy {stuffy_gender_text} didn&apos;t
            notice the tall shadowy monster that had returned to its home. A
            loud moan echoed through the chamber and {stuffy_name_text} looked
            up in horror. The mummy was coming for {stuffy_gender_3rd}!{" "}
            {stuffy_name_text} tried to run away but {stuffy_gender_possessive}{" "}
            pockets were weighted down with gold and {stuffy_gender_text}{" "}
            couldn&apos;t move. Just as the mummy reached for him,{" "}
            {stuffy_name_text} slipped out of {stuffy_gender_possessive} jacket,
            jumped out of the sarcophagus, and sprinted out of the tomb.{" "}
            {capitalizeFirstLetter(stuffy_gender_text)} ran back to the camp and
            hid in {stuffy_gender_possessive} sleeping bag, but couldn&apos;t
            sleep because {stuffy_gender_text} was so scared.
          </p>
          <p>
            In the morning, {kid_name_text} had some breakfast and packed things
            up. “Hurry up {stuffy_name_text},” {kid_gender_text} said. “Why are
            you moving so slowly? And where is your jacket?”
          </p>
          <p>
            {stuffy_name_text} told {kid_name_text} everything that happened.
            “You were right, {kid_name_text}. I shouldn&apos;t have gone into
            the mummy&apos;s tomb.” {stuffy_name_text} felt sad and very tired.
            “I don&apos;t think we&apos;ll ever find Twig.”
          </p>
          <p>
            They started walking and were passing some tall stone columns. All
            of a sudden, {stuffy_name_text} said: “Look!” There, carved into the
            stone, was the letter T and an arrow pointing ahead.
          </p>
          <p id="last-paragraph">
            “It must have been carved by Twig,” {kid_name_text} said. “He&apos;s
            trying to tell us that he went this way!”
          </p>

          <Link to={"/product/longjourney2"} className="to-be-continued">
            To be continued in Part 2...
          </Link>
        </div>
      </div>
    </>
  );
};

Story.propTypes = {
  kid_name: PropTypes.string,
  kid_gender: PropTypes.string,
  stuffy_name: PropTypes.string,
  stuffy_gender: PropTypes.string,
};

export default Story;
