import * as React from "react";
import PropTypes from "prop-types";
import Stars from "../components/stars";
import { Link } from "react-router-dom";
import { getImage } from "../utils/image-fetcher";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "./story-card.scss";

const StoryCard = ({ data }) => {
  return (
    <Link to={"/product/" + data.slug} className="story-card">
      <div
        className="story-book-cover"
        style={{ backgroundColor: data.card_color }}
      >
        {data.locked ? (
          <div className="locked-overlay">
            <FontAwesomeIcon icon={faLock} className="lock-icon " />
          </div>
        ) : null}

        <img src={getImage(data.image)} alt={data.descriptive_title} />
        {data.new ? <div className="new-tag">NEW</div> : null}
      </div>

      <div className="card-title-section">
        <div className="book-title">{data.title}</div>
        <div className="book-descriptive-title">
          <b>{data.descriptive_title}</b>
          {data.descriptive_subtitle ? ": " : null}
          {data.descriptive_subtitle}
          <Stars star_number={data.review_average} />
          <div>Length: {data.length} min</div>
          <div>Ages: 4-8</div>
        </div>

        {data.locked ? null : <div className="book-price">FREE</div>}
      </div>
    </Link>
  );
};

StoryCard.propTypes = {
  data: PropTypes.object,
};

export default StoryCard;
