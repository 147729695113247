import * as React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import NameInput from "./name-input";
import GenderRadioButtons from "./gender-radio-btns";
import "./story.scss";

const StorySetup = ({
  step,
  input_label,
  name,
  setName,
  gender,
  setGender,
  slug,
  changeStep,
}) => {
  return (
    <div className="" key="child">
      <div className="step-completed">
        <div className="step-completed-bar step1">
          <span className="step-completed--inner-bar"></span>
        </div>
        <div className="step-completed-label">Step {step} / 2</div>
      </div>

      <div className="story-inputs">
        <NameInput
          styleName="story-name-wrap"
          label={input_label}
          name={name}
          updateName={setName}
        />

        <GenderRadioButtons
          styleName="sparrow-radio-label"
          gender={gender}
          setGender={setGender}
        />
      </div>

      <div className="go-btn-wrapper">
        <button
          className="story-go-btn"
          disabled={!gender || !name}
          onClick={() => {
            changeStep();
            ReactGA.event({
              category: "story",
              action: "step_" + JSON.stringify(step) + "_done",
              label: slug,
            });
          }}
        >
          next
        </button>
      </div>
    </div>
  );
};

StorySetup.propTypes = {
  step: PropTypes.number,
  input_label: PropTypes.string,
  name: PropTypes.string,
  setName: PropTypes.func,
  gender: PropTypes.string,
  setGender: PropTypes.func,
  slug: PropTypes.string,
  changeStep: PropTypes.func,
};

export default StorySetup;
