import * as React from "react";
import PropTypes from "prop-types";
import "./story.scss";
import { getImage } from "../utils/image-fetcher";

// Import Swiper React components
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

// Import Swiper styles
import "swiper/swiper-bundle.css";

const StoryHeader = ({ title, author, background_info, pics }) => {
  return (
    <div className="story-top">
      <div className="story-title">{title}</div>
      <div className="story-author">{author}</div>
      <div className="background-text">{background_info}</div>
      <div className="header-images">
        <Swiper
          className="mySwiper"
          navigation
          pagination={{ clickable: true }}
          modules={[Navigation]}
        >
          {pics.map((pic, i) => {
            return (
              <SwiperSlide key={i} className="swiper-fixed-width-300">
                <img src={getImage(pic)} className="story-pic" />
                <div className="carousel-image-text">{pic}</div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

StoryHeader.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  background_info: PropTypes.object,
  pics: PropTypes.array,
};

export default StoryHeader;
