// import logo from "./logo.svg";
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import HomeImage from "./images/home.jpg";
import Home from "./pages/home.js";
import About from "./pages/about.js";
import Contact from "./pages/contact.js";
import Login from "./pages/login.js";
import Library from "./pages/library.js";
import Trial from "./pages/trial.js";
import SampleStory from "./pages/sample-story.js";
import NotFound from "./pages/not-found.js";
import StoryProduct from "./pages/story-product.js";
import PrivacyPolicy from "./pages/privacy-policy.js";
import ScrollToTop from "./components/scrolltotop.js";
import ReactGA from "react-ga";
import "./App.scss";

function App() {
  ReactGA.initialize("UA-208873965-1");
  let location = useLocation();
  let currentUrl = "https://www.believeinbedtime.com" + location.pathname;
  let quote = "";
  let title = "Believe in Bedtime - Personalized Bedtime Stories for Kids";
  let image = HomeImage;
  let description =
    "Believe in Bedtime brings you personalized bedtime stories to read online.  Start a free story now and make bedtime better.";
  let hashtag = "#believeinbedtime";

  return (
    <div className="App">
      <ScrollToTop>
        <Helmet>
          <title>{title}</title>
          <meta charset="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta property="url" content={currentUrl} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta property="title" content={title} />
          <meta property="quote" content={quote} />
          <meta name="description" content={description} />
          <meta property="image" content={image} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:quote" content={quote} />
          <meta property="og:hashtag" content={hashtag} />
          <meta property="og:image" content={image} />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={currentUrl} />
          <meta property="og:site_name" content="CampersTribe" />
          <meta property="og:description" content={description} />
        </Helmet>

        {/* update corresponding sitemap-routes to keep sitemap in sync */}
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="sample-story" element={<SampleStory />} />
          <Route path="contact" element={<Contact />} />
          <Route path="library" element={<Library />} />
          <Route path="product/:slug" element={<StoryProduct />} />
          <Route path="login" element={<Login />} />
          <Route path="trial" element={<Trial />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;
