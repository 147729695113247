import * as React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import "./page-styles.scss";
import "./contact.scss";

const Contact = () => {
  return (
    <>
      <Header />
      <Helmet
        link={[
          {
            rel: "canonical",
            key: "https://www.believeinbedtime.com/contact",
            href: "https://www.believeinbedtime.com/contact",
          },
        ]}
      >
        <title>Contact Believe in Bedtime</title>
        <meta
          name="description"
          content="Contact us to give feedback or ideas on making better online bedtime stories."
        />
      </Helmet>
      <div className="main-content contact-page">
        <h1 className="page-title">We’d Love to Hear From You</h1>
        <div className="contact-page--subtitle">
          Whether you’re curious about features, need support, or have feedback
          – we’re here to answer any and all questions
        </div>
        <div className="contact-page--blocks">
          <iframe
            style={{ display: "flex", flex: 1 }}
            src="https://us20.list-manage.com/contact-form?u=badb2fbad029e1efe4d953bf3&form_id=79b53a8016ae1c4ded8d2b3feae5f58e"
          />
          <div className="contact--email-block">
            <div className="contact--email-block-text">
              Or Email Us at&nbsp;
              <a
                className="contact-email"
                href="mailto:hello@believeinbedtime.com"
              >
                hello@believeinbedtime.com
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
