import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Collector1 from "./collector1";
import Collector2 from "./collector2";
import MrWiggleworm from "./mrwiggleworm";
import WrongTower1 from "./wrongtower1";
import WrongTower2 from "./wrongtower2";
import LongJourney1 from "./longjourney1";
import LongJourney2 from "./longjourney2";
import ReactGA from "react-ga";

const StoryWrapper = ({
  slug,
  kid_name,
  kid_gender,
  stuffy_name,
  stuffy_gender,
}) => {
  const [reachedBottom, setReachedBottom] = useState(false);

  useEffect(() => {
    const onScroll = function () {
      let box = document.getElementById("root");
      if (
        window.innerHeight + window.scrollY > 2000 &&
        window.innerHeight + window.scrollY >= box.offsetHeight
      ) {
        setReachedBottom(true);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (reachedBottom) {
      // var element = document.getElementById("story-end");
      // element.classList.remove("hide-until-storys-done");
      ReactGA.event({
        category: "story",
        action: "end_reached",
        label: slug,
      });
    }
  }, [reachedBottom]);

  switch (slug) {
    case "sample-story":
      return (
        <Collector1
          kid_name={kid_name}
          kid_gender={kid_gender}
          stuffy_name={stuffy_name}
          stuffy_gender={stuffy_gender}
        />
      );
    case "collector1":
      return (
        <Collector1
          kid_name={kid_name}
          kid_gender={kid_gender}
          stuffy_name={stuffy_name}
          stuffy_gender={stuffy_gender}
        />
      );
    case "collector2":
      return (
        <Collector2
          kid_name={kid_name}
          kid_gender={kid_gender}
          stuffy_name={stuffy_name}
          stuffy_gender={stuffy_gender}
        />
      );
    case "mrwiggleworm":
      return (
        <MrWiggleworm
          kid_name={kid_name}
          kid_gender={kid_gender}
          stuffy_name={stuffy_name}
          stuffy_gender={stuffy_gender}
        />
      );
    case "wrong-tower1":
      return (
        <WrongTower1
          kid_name={kid_name}
          kid_gender={kid_gender}
          stuffy_name={stuffy_name}
          stuffy_gender={stuffy_gender}
        />
      );
    case "wrong-tower2":
      return (
        <WrongTower2
          kid_name={kid_name}
          kid_gender={kid_gender}
          stuffy_name={stuffy_name}
          stuffy_gender={stuffy_gender}
        />
      );
    case "longjourney1":
      return (
        <LongJourney1
          kid_name={kid_name}
          kid_gender={kid_gender}
          stuffy_name={stuffy_name}
          stuffy_gender={stuffy_gender}
        />
      );
    case "longjourney2":
      return (
        <LongJourney2
          kid_name={kid_name}
          kid_gender={kid_gender}
          stuffy_name={stuffy_name}
          stuffy_gender={stuffy_gender}
        />
      );
    default:
      return <div>Story not found</div>;
  }
};

StoryWrapper.propTypes = {
  slug: PropTypes.string,
  kid_name: PropTypes.string,
  kid_gender: PropTypes.string,
  stuffy_name: PropTypes.string,
  stuffy_gender: PropTypes.string,
};

export default StoryWrapper;
