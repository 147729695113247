// import * as React from "react";
// import PropTypes from "prop-types";
// import "./story.scss";
import Freddy from "../images/freddy.jpg";
import Twig from "../images/twig.jpg";
import Cabin from "../images/cabin.jpg";
import Lake from "../images/lake.jpg";
import Woods from "../images/woods.jpg";
import Bobo from "../images/bobo.jpeg";
import Rocky from "../images/rocky.jpg";
import Snake from "../images/snake.jpeg";
import Candle from "../images/candle.jpeg";
import Rhino from "../images/rhino.jpeg";
import BabaYagaTower from "../images/babayagatower.jpg";
import Scarecrow from "../images/scarecrow.jpg";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";

export const getImage = (pic) => {
  switch (pic) {
    case "Freddy":
      return Freddy;
    case "Twig":
      return Twig;
    case "Lake":
      return Lake;
    case "Cabin":
      return Cabin;
    case "Woods":
      return Woods;
    case "Bobo":
      return Bobo;
    case "Rocky":
      return Rocky;
    case "Snake":
      return Snake;
    case "Candle":
      return Candle;
    case "Rhino":
      return Rhino;
    case "Scarecrow":
      return Scarecrow;
    case "Baba Yaga's Tower":
      return BabaYagaTower;
    default:
      return null;
  }
};
