import * as React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons";
import "./stars.scss";

const Stars = ({ star_number }) => {
  const count = star_number ? parseInt(star_number, 10) : 0;
  let has_half_star = false;
  if (star_number && star_number.length > 2) {
    has_half_star = star_number.slice(-2) == ".5";
  }

  return (
    <div>
      {[...Array(count)].map((x, i) => (
        <FontAwesomeIcon key={i} icon={faStar} className="star-icon" />
      ))}
      {has_half_star ? (
        <FontAwesomeIcon icon={faStarHalf} className="star-icon" />
      ) : (
        ""
      )}
    </div>
  );
};

Stars.propTypes = {
  star_number: PropTypes.string,
};

export default Stars;
