import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import StoryHeader from "../components/story-header";

const Story = ({ kid_name, kid_gender, stuffy_name, stuffy_gender }) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const title = "Wrong Tower, Part 2";
  const author = "Written by Chuck Ludeke, copyright 2021";
  const kid_name_text = capitalizeFirstLetter(kid_name);
  const sorcerer_text =
    kid_gender === "male"
      ? "sorcerer"
      : kid_gender === "female"
      ? "sorceress"
      : "sorcerer";
  const kid_gender_text =
    kid_gender === "male" ? "he" : kid_gender === "female" ? "she" : "they";
  const kid_gender_possessive =
    kid_gender === "male" ? "his" : kid_gender === "female" ? "her" : "their";

  const stuffy_name_text = capitalizeFirstLetter(stuffy_name);
  const stuffy_gender_text =
    stuffy_gender === "male"
      ? "he"
      : stuffy_gender === "female"
      ? "she"
      : "they";
  const stuffy_gender_possessive =
    stuffy_gender === "male"
      ? "his"
      : stuffy_gender === "female"
      ? "her"
      : "their";
  const stuffy_gender_3rd =
    stuffy_gender === "male"
      ? "him"
      : stuffy_gender === "female"
      ? "her"
      : "them";

  // const was_verb_text = stuffy_gender === "nonbinary" ? "were" : 'was'
  return (
    <div>
      <StoryHeader
        title={title}
        author={author}
        pics={["Scarecrow", "Baba Yaga's Tower", "Rocky"]}
        background_info={
          <div>
            In this story, {kid_name_text} and {stuffy_name_text} live in a
            tower in the woods with their other animal friends. Further north in
            a different tower, lives a witch named Baby Yaga. If you
            haven&apos;t read part 1 yet, you can read it{" "}
            <Link className="text-link" to={"/product/wrong-tower2"}>
              here
            </Link>
          </div>
        }
      />
      {/* <div>
          In this story, {kid_name_text} and {stuffy_name_text} live in a tower
          in the woods with their other animal friends. Further north in a
          different tower, lives a witch named Baby Yaga. If you haven&apos;t
          read part 1 yet, you can read it{" "}
          <Link href="/wrong-tower1">here</Link>
        </div> */}
      {/* </StoryHeader> */}

      <div className="main-story-wrap">
        <p>
          It was a dark and stormy evening and all the animals were talking
          about how {stuffy_name_text} had not come home yet. Freddy the fox had
          just returned from searching for {stuffy_name_text}’s footprints and
          reported that they appeared to be headed north toward Baba Yaga’s
          tower.
        </p>
        <p>
          “That’s what I was afraid of,” {kid_name_text} said. “I’ve long
          suspected that the witch was behind our monster troubles. And now she
          may have lured {stuffy_name_text} into her clutches.”
        </p>
        <p>
          Rocky, who was looking out the window, asked: “{kid_name_text}, do
          scarecrows come to life?”
        </p>
        <p>“No,” {kid_name_text} said.</p>
        <p>
          “Well, maybe ours did, because it’s missing.” Everyone ran to the
          window and looked out.
        </p>
        <p>
          “How strange!” Freddy said. “But what are you going to do about{" "}
          {stuffy_name_text}, {kid_name_text}?”
        </p>
        <p>
          “I’m going to Baba Yaga’s tower.” {kid_name_text} replied. “I think{" "}
          {stuffy_gender_text} could be there.”
        </p>
        <p>“Oh my!” Twig said. “They say the tower is full of goblins!”</p>
        <p>
          Freddy, who had the sniffles, said: “It’s too bad I have a cold
          because I’d march inside that tower and bonk those goblins on the
          head!”
        </p>
        <p>
          Rocky laughed. “Oh really?” he said. “I think you’re afraid of
          goblins!”
        </p>
        <p>“I am not!”</p>
        <p>“You are!”</p>
        <p>“I am not!”</p>
        <p>“You are!”</p>
        <p>
          “Quiet down!” {kid_name_text} said. “Rocky, you can see well in the
          dark, and you’re a good climber, right?”
        </p>
        <p>“Yep!” Rocky said proudly. “And I’m not afraid of goblins!”</p>
        <p>
          “Good!” {kid_name_text} said. “Pack a long rope. You’re coming with
          me!”
        </p>
        <p>Rocky’s eyes got big and he swallowed. “Uh, I am?”</p>
        <p>
          Minutes later, {kid_name_text} and Rocky were headed north, hiking
          quickly through the dark.
        </p>
        <div className="story-space"></div>
        <p>
          Meanwhile, in Baba Yaga’s tower, {stuffy_name_text} had fallen asleep,{" "}
          {stuffy_gender_possessive} head resting on the scarecrow’s shoulder.
        </p>
        <p>
          “Hey you … wake up,” Bomba whispered. He was poking {stuffy_name_text}{" "}
          with the key he had used to open the dungeon door.
        </p>
        <p>
          {stuffy_name_text} rubbed {stuffy_gender_possessive} eyes. “Oh. Hello
          Bomba.”
        </p>
        <p>
          “You must run and hide!” Bomba said. “Baba Yaga might feed you to
          Shadowcreeper!”
        </p>
        <p>“Who’s Shadowcreeper?”</p>
        <p>“The giant spider in the deepest dungeon!”</p>
        <p>
          “Jeepers Creepers!” {stuffy_name_text} said. “Thanks for helping me,
          Bomba!” {stuffy_name_text} ran out of the dungeon cell, right past the
          sleeping hobgoblin, and down the corridor.{" "}
          {capitalizeFirstLetter(stuffy_gender_text)} ran past other dungeon
          cells, and in one of them a young elf said: “Wait! Help me!”{" "}
          {stuffy_name_text} couldn’t open the door, but the elf slipped{" "}
          {stuffy_gender_possessive} a note. When {stuffy_name_text} heard
          goblins coming {stuffy_gender_text} ran up some stairs and into a big
          room filled with tables and benches. There {stuffy_gender_text} hid in
          an empty barrel and kept quiet. But {stuffy_gender_text} was so tired{" "}
          {stuffy_gender_text} soon fell asleep again.
        </p>
        <div className="story-space"></div>
        <p>
          {kid_name_text} and Rocky arrived at Baba Yaga’s shortly before
          midnight. Rocky looked up at the strange crooked tower and all the
          bats and ravens flying about. “This place is scary!” he said.
        </p>
        <p>
          On the path that led up to the tower, several goblins were sitting
          around a campfire and laughing. {kid_name_text} and Rocky listened
          from behind a nearby rock. A big fat goblin was talking: “I heard
          Snotty and Fester stole a scarecrow by accident from the{" "}
          {sorcerer_text}! Baba Yaga was so mad, but she had already captured
          someone else. If the {sorcerer_text} comes here, she’ll feed both of
          them to Shadowcreeper!”
        </p>
        <p>
          Now {kid_name_text} understood what had happened.{" "}
          {capitalizeFirstLetter(kid_name_text)} formed a plan and told Rocky to
          climb up the backside of the tower, sneak in a window and tie a rope
          that dangled down to the ground. “I’ll find {stuffy_name_text}, and
          then we’ll meet up with you and climb down the rope to get away.”
        </p>
        <p>
          “Okay,” Rocky said. He took the rope, ran to the back of the tower,
          and started climbing up toward an open window.
        </p>
        <p>
          {kid_name_text} pulled the hood of {kid_gender_possessive} black cape
          up over {kid_gender_possessive} head and walked up to the goblins by
          the tower door. “I am the {sorcerer_text}. Take me to the dungeons!”
        </p>
        <p>
          The goblins were afraid, but quickly led {kid_name_text} to the
          dungeons. They woke up the big hobgoblin and he locked {kid_name_text}{" "}
          in the dungeon cell with the scarecrow. The goblins were excited and
          ran to tell Baba Yaga that the {sorcerer_text} had surrendered!
        </p>
        <p>
          The big hobgoblin scratched his head. “Where little prisoner?” he
          asked.
        </p>
        <p>
          {kid_name_text} realized that {stuffy_name_text} must already have
          escaped. “Prisoner sneak away,” {kid_name_text} said. “You must find{" "}
          {stuffy_gender_3rd}!”
        </p>
        <p>
          “Me find {stuffy_gender_3rd}!” hobgoblin said and he wandered down the
          hall. Now {kid_name_text} looked at the scarecrow and had an idea.{" "}
          {kid_name_text} took off {kid_gender_possessive} black cape and folded
          it up. Then {kid_gender_text} pulled all the straw out of the
          scarecrow, and put on the scarecrow’s clothes, face mask, and hat.{" "}
          {kid_name_text} stuffed {kid_gender_possessive} cape under{" "}
          {kid_gender_possessive} shirt. Then {kid_name_text} slumped down on
          the floor and lay perfectly still. Soon the goblins returned with Baba
          Yaga and the hobgoblin guard unlocked the door. They stared at the
          empty cell, with only a scarecrow lying on the floor.
        </p>
        <p>
          “{capitalizeFirstLetter(kid_gender_text)}’s gone!” cried one the
          goblins.
        </p>
        <p>
          “Maybe {kid_gender_text} was only a ghost,” said another. And they all
          looked around nervously as if the dungeon was haunted.
        </p>
        <p>
          “You fools!” Baba Yaga shouted. “They’ve escaped! Find that{" "}
          {sorcerer_text}! Find the little prisoner! Or I’ll feed all of you to
          Shadowcreeper!”
        </p>
        <p>
          The goblins and hobgoblin hurried away, searching for {kid_name_text}{" "}
          and {stuffy_name_text}. And Baba Yaga went back to her tower room to
          get her broomstick. When everyone was gone, {kid_name_text} the
          scarecrow stood up. The door to the dungeon cell was left open, so{" "}
          {kid_name_text} walked quietly out of the cell and into the hallway.{" "}
          {capitalizeFirstLetter(kid_gender_text)} hid in the shadows as several
          goblins walked by, and heard them talking.
        </p>
        <p>
          “I don’t like these dungeons,” said one goblin. “Shadowcreeper is down
          here somewhere and other monsters too.”
        </p>
        <p>
          {kid_name_text} suddenly jumped out of the shadows and said: “Boo!”
        </p>
        <p>“Aaaahhhhhh!!!!” the goblins cried, and they ran away screaming.</p>
        <div className="story-space"></div>
        <p>
          In the meantime, Rocky climbed through a window into a small kitchen
          with a winding stairway in the corner. A big goblin cook was standing
          by the oven stirring a large pot. “I’m tired of making caterpillar
          soup,” the cook said to himself. “Maybe today I’ll make bat-poop
          soup.” He turned around and saw Rocky. “Hey you! Take that broom and
          go up to Baba Yaga’s room and sweep it. Bomba said he spilled a jar of
          spider legs.” The cook pointed up the stairs.
        </p>
        <p>
          “Uh, okay,” Rocky said. He took the broom and ran upstairs. When Rocky
          walked into Baba Yaga’s room, Bomba the bat said, “You’re a strange
          looking goblin.”
        </p>
        <p>
          “And you’re a strange looking bat,” Rocky said. “Anyway, the cook sent
          me up here to sweep.”
        </p>
        <p>
          “Oh good,” Bomba said, and pointed to the pile of spider legs on the
          floor, some of which were still twitching.
        </p>
        <p>
          Rocky started sweeping and looking around. He noticed Baba Yaga’s
          broomstick in the corner. It looked kind of like the old broom he was
          using to sweep. When Bomba wasn’t looking, Rocky switched his broom
          for Baba Yaga&apos;s. Then he took Baba Yaga’s broomstick back down to
          the kitchen and left it in the corner.
        </p>
        <p>
          The goblin cook said, “Come here. Taste this bat-poop soup I made and
          tell me what you think.”
        </p>
        <p>
          It smelled terrible, so Rocky only pretended to take a sip. “Yum,” he
          said, “it’s delicious!”
        </p>
        <p>
          “Good!” the cook said. “Now take this big pot of soup into the dining
          hall.”
        </p>
        <p>
          Rocky carried the heavy pot into the room filled with tables and
          benches. Goblins were coming in for their midnight supper. One of them
          said to Rocky: “You sure are a funny looking goblin.”
        </p>
        <p>
          “Oh yeah?” Rocky said. “Well, your face looks like a monster cookie!”
        </p>
        <p>
          All the other goblins laughed. A moment later, three goblins came in
          talking about the scarecrow that had come to life. The goblin cook
          also came in and sat down. He said: “Ain’t no such thing as scarecrows
          that come to life!”
        </p>
        <p>
          Just then the door swung slowly open, creaking on its hinges, and all
          the goblins looked to see who it was. There stood the scarecrow! The
          goblins jumped up and ran all around screaming. One of them knocked
          over a barrel and {stuffy_name_text} tumbled out, but no one noticed.
          The scarecrow chased the goblins out of the room.
        </p>
        <p>
          Rocky saw {stuffy_name_text} and took {stuffy_gender_3rd} back to the
          kitchen with the window. “Come on,” Rocky said. He tied the rope to
          the kitchen table leg and let the other end dangle out the window down
          to the ground. “Jeepers Creepers!” {stuffy_name_text} said. “How did
          the scarecrow come to life?”
        </p>
        <p>
          “I don’t know,” Rocky said, “but I hope it didn’t get {kid_name_text}
          !”
        </p>
        <p>
          At that moment the scarecrow walked in to the kitchen and stood
          looking at them. Rocky and {stuffy_name_text} were so scared they
          almost fainted.
        </p>
        <p>
          “It’s just me,” {kid_name_text} said. And {kid_gender_text} pulled the
          scarecrow mask off. “Come on. Let’s get out of here!” And{" "}
          {kid_name_text}, {stuffy_name_text}, and Rocky climbed down the rope
          and escaped from the tower.
        </p>
        <p>
          Now Baba Yaga flew into a rage when she heard about the scarecrow and
          how no one could find the {sorcerer_text} or {kid_gender_possessive}{" "}
          friend. She hopped on the old broom, which she thought was her magical
          broomstick, and jumped from the tower window. But instead of flying,
          she fell straight down into a huge puddle of mud.
        </p>
        <p>
          Bomba the bat saw it happen and giggled. Later, he heard that the cook
          tried to sweep the kitchen floor, but the broom kept running away from
          him.
        </p>
        <p>
          The next day, when {kid_name_text}, Rocky, and {stuffy_name_text} were
          home safely, everyone was talking about their adventure. “I never want
          to go back there,” Rocky said. “Me too,” said {stuffy_name_text}. Then{" "}
          {stuffy_gender_text} jumped up and said: “I just remembered something!
          Someone in the dungeon gave me this note!”{" "}
          {capitalizeFirstLetter(kid_gender_text)} handed it to {kid_name_text}{" "}
          who unfolded it and read:
        </p>
        <p>
          “Hello, I’m a prisoner in Baba Yaga’s dungeon. Please help me. Anno,
          the elf.”
        </p>
        <p>
          “Hey,” {stuffy_name_text} said. “I remember Anno! We made friends with
          him on our long journey!”
        </p>
        <p>“That’s right,” {kid_name_text} said.</p>
        <p>
          “What will you do, {kid_name_text}?” Rocky asked. And all the little
          friends looked to {kid_name_text}.
        </p>
        <p>“Well,” {kid_name_text} said, “We’ll have to go back!”</p>
        <p>“Oh no!!” everyone cried, and Rocky slumped to the floor.</p>
        <p id="last-paragraph">
          “Poor Rocky,” {stuffy_name_text} said. “I think he fainted!”
        </p>
        <p className="the-end-text">The End</p>
      </div>
    </div>
  );
};

Story.propTypes = {
  kid_name: PropTypes.string,
  kid_gender: PropTypes.string,
  stuffy_name: PropTypes.string,
  stuffy_gender: PropTypes.string,
};

export default Story;
