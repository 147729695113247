import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./header.scss";

const Header = () => {
  const [menu_open, showMobileMenu] = useState(false);

  return (
    <div className="header-wrap">
      <div className="header-ends">
        <Link to="/" className="header-site-name">
          Believe in Bedtime
        </Link>
        <div className="header-menu-links">
          <Link to="/" className="header-link">
            Home
          </Link>
          <Link to="/about" className="header-link">
            About
          </Link>
          <Link to="/library" className="header-link">
            Library
          </Link>
          <Link to="/login" className="header-link">
            Login
          </Link>
        </div>
      </div>

      <div className="header-ends">
        <Link to="/trial" className="header-link free-trial-link">
          Start a Free Trial
        </Link>
        <FontAwesomeIcon
          icon={faBars}
          className="header-menu-icon"
          onClick={() => showMobileMenu(!menu_open)}
        />
      </div>

      {menu_open ? (
        <div className="mobile-menu">
          <div className="mobile-menu-close">
            <FontAwesomeIcon
              icon={faTimes}
              className="mobile-menu-close--icon"
              onClick={() => showMobileMenu(false)}
            />
          </div>
          <Link to="/about" className="header-link">
            About
          </Link>
          <Link to="/library" className="header-link">
            Library
          </Link>
          <Link to="/login" className="header-link">
            Login
          </Link>
          <Link to="/trial" className="header-link mobile-trial-link">
            Start a Free Trial
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
