import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import StoryHeader from "../components/story-header";

const Story = ({ kid_name, kid_gender, stuffy_name, stuffy_gender }) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const title = "Wrong Tower, Part 1";
  const author = "Written by Chuck Ludeke, copyright 2021";
  const kid_name_text = capitalizeFirstLetter(kid_name);
  const sorcerer_text =
    kid_gender === "male"
      ? "sorcerer"
      : kid_gender === "female"
      ? "sorceress"
      : "sorcerer";
  const kid_gender_text =
    kid_gender === "male" ? "he" : kid_gender === "female" ? "she" : "they";
  const kid_gender_possessive =
    kid_gender === "male" ? "his" : kid_gender === "female" ? "her" : "their";
  const kid_gender_3rd =
    kid_gender === "male" ? "him" : kid_gender === "female" ? "her" : "them";
  //   const kid_self =
  //    kid_gender === "male"
  //       ? "himself"
  //       : stuffy_gender === "female"
  //       ? "herself"
  //       : "themselves";

  const stuffy_name_text = capitalizeFirstLetter(stuffy_name);
  const stuffy_gender_text =
    stuffy_gender === "male"
      ? "he"
      : stuffy_gender === "female"
      ? "she"
      : "they";
  const stuffy_gender_possessive =
    stuffy_gender === "male"
      ? "his"
      : stuffy_gender === "female"
      ? "her"
      : "their";
  const stuffy_gender_3rd =
    stuffy_gender === "male"
      ? "him"
      : stuffy_gender === "female"
      ? "her"
      : "them";

  // const was_verb_text = stuffy_gender === "nonbinary" ? "were" : 'was'
  return (
    <div>
      <StoryHeader
        title={title}
        author={author}
        background_info={
          "In this story, " +
          kid_name_text +
          " and " +
          stuffy_name_text +
          " live in a tower in the woods with their other animal friends. Further north in a different tower, lives a witch named Baby Yaga."
        }
        pics={["Scarecrow", "Baba Yaga's Tower"]}
      />

      <div className="main-story-wrap">
        <p>
          Vegetables were growing in {stuffy_name_text}’s little garden, but{" "}
          {stuffy_gender_text} was unhappy.{" "}
          <span className="stuffy-font">“{kid_name_text},”</span>{" "}
          {stuffy_gender_text} said,{" "}
          <span className="stuffy-font">
            “the crows are picking at my plants. I chase them away, but they
            always come back.”
          </span>
        </p>
        <p>
          <span className="kid-font">“You need a scarecrow,”</span>{" "}
          {kid_name_text} said.{" "}
          <span className="kid-font">
            “The crows will stay away if they think someone’s watching.”
          </span>
        </p>
        <p>
          <span className="stuffy-font">“How do I make a scarecrow?”</span>
        </p>
        <p>
          {kid_name_text} showed {stuffy_name_text} how to take an old shirt and
          pants and stuff them with straw. They used a big sock to make a head,
          drew a face on it, and stuck an old hat on top. They tied everything
          together and attached it to a stick in the garden. To the crows, it
          looked like a little person was guarding the garden.
        </p>
        <p>
          <span className="stuffy-font">“Good,”</span> {stuffy_name_text} said.{" "}
          <span className="stuffy-font">
            “Now I can do something else… like go for a hike and catch
            butterflies.”
          </span>{" "}
          And so {stuffy_gender_text} did. It was a little chilly, so{" "}
          {stuffy_gender_text} brought a coat. And since there was also a little
          cloud in the sky, {stuffy_gender_text} brought{" "}
          {stuffy_gender_possessive} hat, in case it rained.
        </p>
        <p>
          At that moment, far to the north, Baba Yaga called ten goblins up to
          her tower room. They were afraid of Baba Yaga and stood quietly,
          picking their noses. She told them about the magical {sorcerer_text} (
          {kid_name_text}) that had moved into the south tower and carefully
          explained that they were to capture one of {kid_gender_possessive}{" "}
          friends and bring them back here.{" "}
          <span className="baba-font">“Then,”</span> she said,{" "}
          <span className="baba-font">
            “the {sorcerer_text} will come here to rescue{" "}
            {kid_gender_possessive} friend and I’ll capture {kid_gender_3rd}!
            Isn’t that a good plan?”
          </span>
          “Yes, yes!” they said. And off they went. But goblins are not very
          smart. One tried to swim across a river and was chased by a crocodile.
          One climbed a tree and couldn’t get down again. Another picked a fairy
          carrot and disappeared. Two were caught in a giant spider web. Three
          wandered into a deep cave where a dragon lived. Finally, the last two,
          Fester and Snotty, had gotten lost, but were actually not far from{" "}
          {kid_name_text}’s tower.
        </p>
        <p>
          Meanwhile, {stuffy_name_text} had chased a pretty little butterfly for
          an hour before {stuffy_gender_text} finally gave up. And now{" "}
          {stuffy_gender_text} was lost. But {stuffy_gender_text} saw two
          goblins walking down the trail, so {stuffy_gender_text} hid behind a
          tree. They were talking about capturing someone and didn’t see{" "}
          {stuffy_name_text}. They walked right past {stuffy_gender_possessive}{" "}
          hiding place. <span className="stuffy-font">“Hmmmm,”</span>{" "}
          {stuffy_name_text} thought,{" "}
          <span className="stuffy-font">
            “they’re probably going to Baba Yaga’s tower, so I’ll go the
            opposite way.”
          </span>
        </p>
        <p>
          Now Fester and Snotty arrived at {kid_name_text}’s tower quite by
          accident. It was just after noon and starting to get cloudy.{" "}
          {kid_name_text} and {kid_gender_possessive} friends were in the tower
          eating lunch. Rocky the Raccoon went to the window and looked out.{" "}
          <span className="rocky-font">
            “I hope {stuffy_name_text} gets home soon,”
          </span>{" "}
          Rocky said.{" "}
          <span className="rocky-font">“It looks like it might rain.”</span> He
          didn’t see the goblins who were hiding behind a tree.
        </p>
        <p>“So this is where the {sorcerer_text} lives,” Snotty said.</p>
        <p>
          “I’m scared,” Fester said. “I heard {kid_gender_text} has a magic wand
          and that {kid_gender_text} killed the Jabberwocky and the Bandersnatch
          monsters! Bomba the bat told me that this {sorcerer_text} is meaner
          than a dragon, and has three heads!”
        </p>
        <p>“Bomba’s crazy,” Snotty said. “Nobody could have three heads.”</p>
        <p>“My momma did,” Fester said.</p>
        <p>
          “Well, anyway, all we have to do is capture one of{" "}
          {kid_gender_possessive} little friends. &quot;Look! There&apos;s
          someone standing in the garden! We’ll grab him!”
        </p>
        <p>
          “Good idea!” Fester said. And they snuck up behind the scarecrow,
          quickly threw a bag over him, and carried him away.
        </p>
        <p>“Gee,” Fester said. “He didn’t fight back all.”</p>
        <p>“Yeah, he was probably pretty scared of us,” Snotty said.</p>
        <p>“And he isn’t even making a sound,” Fester said.</p>
        <p>
          “Well, he probably fell asleep,” Snotty said. And they started the
          long march back to Baba Yaga’s tower, carrying the scarecrow in the
          bag.
        </p>
        <p>
          Not far from Baba Yaga’s tower, {stuffy_name_text} put on{" "}
          {stuffy_gender_possessive} coat and hat. It had started to rain.{" "}
          <span className="stuffy-font">
            “I’ve been hiking a long time. I should be pretty close to home by
            now,”
          </span>{" "}
          {stuffy_gender_text} thought. The rain made it hard to see, but{" "}
          {stuffy_gender_text} saw a path leading up to a tower and thought that
          it must be {kid_name_text}’s tower.
        </p>
        <p>
          <span className="stuffy-font">“It is strange,”</span>{" "}
          {kid_gender_text} thought,{" "}
          <span className="stuffy-font">
            “that there are so many ravens and bats flying around.”
          </span>{" "}
          But {stuffy_gender_text} kept going, and imagined warming up by the
          fire, and have one of {kid_name_text}’s cookies. {stuffy_name_text}{" "}
          finally reached the tower and banged on the front door.
        </p>
        <p>
          <span className="stuffy-font">“I’m home!”</span> {stuffy_gender_text}{" "}
          shouted. <span className="stuffy-font">“Let me in!”</span>
        </p>
        <p>
          After a minute, the door slowly opened and {stuffy_name_text} was
          looking up at the biggest hobgoblin {stuffy_gender_text} had ever
          seen.
        </p>
        <p>
          <span className="stuffy-font">“Who are you?”</span> {stuffy_name_text}{" "}
          asked.{" "}
          <span className="stuffy-font">
            {" "}
            “You must be new here. Where’s {kid_name_text}?”
          </span>
        </p>
        <p>The hobgoblin was confused. “Who {kid_name_text}?” he said.</p>
        <p>
          <span className="stuffy-font">“Don’t you know anything?”</span>{" "}
          {stuffy_name_text} said.{" "}
          <span className="stuffy-font">
            “Listen, I’m going upstairs to find {kid_name_text}.”
          </span>{" "}
          {stuffy_name_text} ran up the stone steps, eager to see{" "}
          {stuffy_gender_possessive} friends.{" "}
          <span className="stuffy-font">
            “Gee, so many steps winding up and up,”
          </span>{" "}
          {stuffy_gender_text} thought.{" "}
          <span className="stuffy-font">
            “Everything seems a bit strange here.”
          </span>{" "}
          Finally {stuffy_gender_text} got to the top tower room and saw a messy
          table with a round little bat sitting there.
        </p>
        <p>
          <span className="stuffy-font">“Hello,”</span> {stuffy_name_text} said.{" "}
          <span className="stuffy-font">“Who are you?”</span>
        </p>
        <p>
          <span className="bomba-font">“I’m Bomba.”</span>
        </p>
        <p>
          <span className="stuffy-font">
            “Hi Bomba. Where’s {kid_name_text}? And where’s Freddy and Rocky and
            all the others?”
          </span>
        </p>
        <p>
          <span className="bomba-font">“I don’t know,”</span> Bomba said.{" "}
          <span className="bomba-font">
            “You sure are a funny looking goblin!”
          </span>
        </p>
        <p>
          <span className="stuffy-font">“I’m not a goblin!”</span>{" "}
          {stuffy_name_text} said.{" "}
          <span className="stuffy-font">
            “There are no goblins in {kid_name_text}’s tower!”
          </span>
        </p>
        <p>
          <span className="bomba-font">“Well,”</span> Bomba said.{" "}
          <span className="bomba-font">
            “We have lots of goblins. And this happens to be Baba Yaga’s tower.
            Those are her spell ingredients on the shelves. And that is her
            spell book she used to create the Jabberwocky monster and the
            Bandersnatch monster.”
          </span>
        </p>
        <p>
          {stuffy_name_text} was suddenly very quiet.{" "}
          <span className="stuffy-font">“The...wrong...tower,”</span>{" "}
          {stuffy_gender_text} whispered. “I’m at the wrong tower!”{" "}
          {stuffy_name_text} felt like crying.{" "}
          <span className="stuffy-font">I’m so far from home.”</span>
        </p>
        <p>
          <span className="bomba-font">“Where do you live?”</span> Bomba asked.
        </p>
        <p>
          <span className="stuffy-font">
            “I live in the south woods tower,”
          </span>{" "}
          {stuffy_name_text} said.
        </p>
        <p>
          <span className="bomba-font">“Really?”</span> Bomba asked.{" "}
          <span className="bomba-font">
            “Then you must live with the {sorcerer_text} who shoots fireworks!
            Is it true that {kid_gender_text} has three heads and is meaner than
            a dragon?”
          </span>
        </p>
        <p>
          <span className="stuffy-font">“No,”</span> {stuffy_name_text} said,{" "}
          <span className="stuffy-font">“{kid_gender_text}’s my friend.”</span>{" "}
          {stuffy_name_text} suddenly jumped up on a chair and grabbed Baba
          Yaga’s spell book. It was so big {stuffy_gender_text} could hardly
          carry it.
        </p>
        <p>
          <span className="bomba-font">“What are you doing?”</span> Bomba asked.
        </p>
        <p>
          <span className="stuffy-font">
            “I can’t let that witch make any more monsters,”
          </span>{" "}
          {stuffy_name_text} said.{" "}
          <span className="stuffy-font">“I’m taking…”</span>
        </p>
        <p>
          Suddenly there was great woosh sound as Baba Yaga flew in the window
          on her broomstick.{" "}
          <span className="baba-font">“Well, well, well, well,”</span> she said.
          <span className="baba-font"> “What do we have here?”</span>
        </p>
        <p>
          {stuffy_name_text} stood there holding the big spell book and looking
          up at Baba Yaga. {capitalizeFirstLetter(stuffy_gender_text)} wanted to
          run away, but {stuffy_gender_possessive} legs felt very weak. Baba
          Yaga took her spell book and ordered the guards to come and take{" "}
          {stuffy_name_text} to the dungeon.
        </p>
        <p>
          Now, Fester and Snotty finally returned to Baba Yaga’s tower. They
          gave the bag with their prisoner to the big hobgoblin who took it down
          to the dungeons and put it on the bench next to {stuffy_name_text}.
          When the hobgoblin left, {stuffy_name_text} opened the bag and was
          shocked to see the scarecrow that {stuffy_gender_text} had made.{" "}
          <span className="stuffy-font">“What are you doing here?”</span>{" "}
          {stuffy_gender_text} asked.{" "}
          <span className="stuffy-font">
            “Did you come to life and follow me?”
          </span>
        </p>
        <p>
          Of course, the scarecrow couldn’t answer {stuffy_gender_3rd}. It was
          only made of straw.
        </p>
        <p>
          Minutes later, Fester and Snotty came down to the dungeons with Baba
          Yaga. They proudly pointed at the scarecrow and said: “There’s the
          prisoner! We caught {stuffy_gender_3rd}! Just like you told us to!”
        </p>
        <p>
          <span className="baba-font">“You brainless goblins!”</span> Baba Yaga
          shouted.{" "}
          <span className="baba-font">
            “That isn’t a real person! It’s just a scarecrow! You’re lucky I
            caught this little prisoner here. Go tell the {sorcerer_text} to
            come here and surrender to me, or I’ll feed {kid_gender_possessive}{" "}
            friend to the giant spider!”
          </span>{" "}
          So Fester and Snotty started out again for {kid_name_text}’s tower,
          even though they were very tired and hungry. But since it was now dark
          and still raining, they quickly got lost.
        </p>
        <p id="last-paragraph">
          Meanwhile, {stuffy_name_text} sat alone in the dungeon, with only{" "}
          {stuffy_gender_possessive} scarecrow and a few mice to keep{" "}
          {stuffy_gender_3rd} company.
        </p>
        <Link to={"/product/wrong-tower2"} className="to-be-continued">
          To be continued in Part 2...
        </Link>
      </div>
    </div>
  );
};

Story.propTypes = {
  kid_name: PropTypes.string,
  kid_gender: PropTypes.string,
  stuffy_name: PropTypes.string,
  stuffy_gender: PropTypes.string,
};

export default Story;
