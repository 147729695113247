import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import StoryHeader from "../components/story-header";
const Story = ({ kid_name, kid_gender, stuffy_name }) => {
  const capitalizeFirstLetter = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return null;
  };

  const title = "The Collector, Part 2";
  const author = "Written by Chuck Ludeke, copyright 2021";
  const kid_name_text = capitalizeFirstLetter(kid_name);
  const kid_gender_text =
    kid_gender === "male" ? "he" : kid_gender === "female" ? "she" : "they";
  const kid_gender_possessive =
    kid_gender === "male" ? "his" : kid_gender === "female" ? "her" : "their";
  const kid_gender_3rd =
    kid_gender === "male" ? "him" : kid_gender === "female" ? "her" : "them";

  const stuffy_name_text = capitalizeFirstLetter(stuffy_name);

  return (
    <div>
      <StoryHeader
        title={title}
        author={author}
        background_info={
          <div>
            In part two of this story {kid_name_text} is on a mission to rescue{" "}
            {stuffy_name_text} from the Collector&apos;s house.If you
            haven&apos;t read part 1 yet, you can read it{" "}
            <Link className="text-link" to={"/product/collector1"}>
              here
            </Link>
          </div>
        }
        pics={["Bobo", "Snake", "Candle", "Rhino"]}
      />

      <div className="main-story-wrap">
        <p>
          {stuffy_name_text} and Bobo wandered from room to room in the dark.
          They tip-toed past the sleeping dogs, crept up and down different
          stair cases, until they were completely lost.
        </p>
        <p>
          They were upstairs when they heard a dog coming, so they ran into a
          bedroom. “Quick,” {stuffy_name_text} said, “hide under the bed!” But
          Bobo got mixed up and jumped up on the bed. He bumped into someone
          under the covers. “Excuse me,” he said.
        </p>
        <p>
          “Bobo, where are you? And who are you talking to?” asked{" "}
          {stuffy_name_text}.
        </p>
        <p>
          “I’m on the bed,” Bobo said. “And I don’t know who I’m talking to.”
        </p>
        <p>
          Bobo turned around and gasped. The Collector was looking right at him.
          He snatched up Bobo and {stuffy_name_text}, took them downstairs, and
          put them in a tall glass display case with a sign that read:{" "}
          <i>Python</i>. The Python, a giant snake, was sleeping.
        </p>
        <p>
          “These little trouble makers will make a good snack for my snake when
          he wakes up,” the Collector said. Then he went back to bed.
        </p>
        <p>
          {stuffy_name_text} and Bobo stood quietly in the corner of the python
          case. Then Bobo started to whimper. “Don’t cry now,”{" "}
          {stuffy_name_text} whispered. “We don’t want to wake the snake.”
        </p>
        <p>
          Meanwhile, {kid_name_text} and Freddy the fox had walked to the Land
          of Caves. They soon met a little man with two heads and they asked him
          where the collector’s house was. He thought for a moment and then one
          arm pointed and said: “That way!”.&nbsp; But his other arm pointed in
          the opposite direction and his other head said: “No, this way!” The
          two heads started to argue about which way to go and their foreheads
          kept knocking against each other.&nbsp;
          {kid_name_text} and Freddy decided to go their own way. Just by luck
          they came to a huge old stone mansion with a sign on the door that
          said: The Collector’s House of Strange Creatures. The windows were
          dark and shuttered.
        </p>
        <p>
          {kid_name_text} knocked on the massive front doors. A very loud
          barking erupted inside, and {kid_name_text} could hear sharp claws
          pushing against the doors. Freddy was shaking. “Let’s go home now,” he
          said.
        </p>
        <p>
          “But {stuffy_name_text} might be in there,” {kid_name_text} said.{" "}
          {capitalizeFirstLetter(kid_gender_text)} knocked hard again which
          caused more ferocious barking.
        </p>
        <p>
          A shutter flew open on the second floor and someone called down:
          “We’re closed! And if you bother me again, I’ll let the dogs out to
          get you!”
        </p>
        <p>“Oh my!” Freddy said.</p>
        <p>
          “Courage,” said {kid_name_text}.{" "}
          {capitalizeFirstLetter(kid_gender_text)} sat down on the steps to
          think. All was quiet for a moment, but then they heard someone softly
          crying. The crying was coming from under the steps.&nbsp;
          {kid_name_text} looked under the porch and saw a little bear. They
          learned that the little bear&apos;s name was Felix and he was trying
          to save his brother Bobo, who had been captured by the Collector.
        </p>
        <p>
          “There’s a basement window that is slightly open and I was going to
          sneak in with this magic cloak,” Felix said, “but I’m not brave
          enough. Besides, the cloak is too big for me. You can try it on if you
          want to.”
        </p>
        <p>“What kind of magic cloak is it?” {kid_name_text} asked.</p>
        <p>“I don’t know,” Felix replied. “It probably doesn’t even work.”</p>
        <p>
          {kid_name_text} tried on the black cloak with its black hood. It fit{" "}
          {kid_gender_3rd} perfectly.
        </p>
        <p>
          Felix turned to Freddy the fox and said: “Hey, where did your friend
          go?”
        </p>
        <p>
          “I don’t know,” Freddy said. “{capitalizeFirstLetter(kid_gender_text)}{" "}
          was here a second ago. {kid_name_text}, where are you?”
        </p>
        <p>
          “I’m still here,” {kid_name_text} said. “This must be a cloak of
          invisibility! Now show me that basement window.”
        </p>
        <p>
          Moments later {kid_name_text} slipped into the basement of the
          Collector’s house. It was an enormous old cellar filled with old dusty
          boxes and barrels and cages.&nbsp;
          {capitalizeFirstLetter(kid_gender_text)} tried to turn on{" "}
          {kid_gender_possessive} flashlight but the batteries had died.{" "}
          {kid_name_text} could hear creatures moving in the cages, but it was
          too dark to see what they were.
        </p>
        <p>
          “{stuffy_name_text}? Are you down here?” {kid_name_text} called
          softly.
        </p>
        <p>
          There was no answer, so {kid_gender_text} walked up a long stone ramp
          that led to the first floor. {capitalizeFirstLetter(kid_gender_text)}{" "}
          opened a door into a long hall with many more doors. At one end of the
          hall were three big sleeping dogs.&nbsp;
          {kid_name_text} moved quietly, peeking in different rooms. Most of the
          rooms were lit by a single flickering candle, so it was hard to see.
          Sometimes the floor would squeak, and one of the dogs would look up,
          but they couldn’t see {kid_name_text} because of the magic cloak. And
          once, as {kid_name_text} stood perfectly still, the Collector walked
          by and then stopped, as though he sensed something wasn’t right.
        </p>
        <p>
          “Hmmm,” the Collector said, “sneaky, sneaky, like a mouse, who is
          hiding in my house?” But he couldn’t see anything and went back to
          bed.
        </p>
        <p>
          Finally, {kid_name_text} entered a room with several tall glass cases
          and saw {stuffy_name_text}. {capitalizeFirstLetter(kid_gender_text)}{" "}
          was about to climb up and try to open the top of the case when two of
          the dogs came into the room and started to sniff around. The dogs
          couldn’t see {kid_gender_3rd}, but they seemed to smell something.
          Then {kid_name_text} had an idea.{" "}
          {capitalizeFirstLetter(kid_gender_text)} took the candle and tip-toed
          out of the room. {stuffy_name_text} was surprised to see the candle
          float out of the room by itself.
        </p>
        <p>
          “Oh great,” thought {stuffy_name_text}. “Monsters, dogs, snakes, and
          now ghosts too.”
        </p>
        <p>
          {kid_name_text} returned to the cellar with the candle and went to the
          cages. The first one held a spider that was as big as a cow. “I better
          not open this,” thought {kid_name_text} .
        </p>
        <p>
          The second cage held two giant beetles with long saw-like claws. They
          kept reaching between the bars with their claws. “I better not open
          this one either,” {kid_gender_text} thought.
        </p>
        <p>
          But the next cages held a baby giraffe, a donkey, a kangaroo, and a
          camel. {kid_name_text} opened all those cages. Finally{" "}
          {kid_gender_text} came to a big cage that held a mother rhino and its
          baby. The mother rhino seemed quite angry, but {kid_name_text} decided
          to take a chance and open the cage. As the animals began to wander
          about, {kid_name_text} went back up the ramp and called to them.
        </p>
        <p>
          “Come on camel, come on donkey! Hurry up giraffe, hurry up kangaroo,
          this is the way out.”
        </p>
        <p>
          {kid_name_text} opened the door to the great hall. One by one the
          animals ran up the ramp. They began to snort and bray and clomp their
          hooves. The guard dogs came running and barking, but when they saw the
          mother rhino they yelped and ran away.&nbsp;
          {kid_name_text} opened the front doors of the house, then ran back
          into the room where {stuffy_name_text} was and opened the lid to the
          python case. The python was just starting to wake up, but{" "}
          {kid_name_text} grabbed {stuffy_name_text} and Bobo and ran out of the
          house right behind the baby giraffe.
        </p>
        <p>“What’s happening?” Bobo asked.</p>
        <p>
          “I think we&apos;re being rescued by a ghost!” {stuffy_name_text}{" "}
          said.
        </p>
        <p>“No,” {kid_name_text} said. “It’s just me!”</p>
        <p id="last-paragraph">
          Outside, they met up with Freddy and Felix. Felix was very happy to
          see his brother Bobo and everyone made plans to head back home. All of
          the animals that {kid_name_text} freed were out now, except for the
          mother Rhino. She was busy smashing stuff with her big horn and
          chasing the Collector all around the house.
        </p>
        <p className="to-be-continued">The End</p>
      </div>
    </div>
  );
};

Story.propTypes = {
  kid_name: PropTypes.string,
  kid_gender: PropTypes.string,
  stuffy_name: PropTypes.string,
  stuffy_gender: PropTypes.string,
};

export default Story;
