import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import "./footer.scss";
import { FacebookIcon } from "react-share";

const Footer = () => {
  const footer_height = 300;
  const [reachedBottom, setReachedBottom] = useState(false);

  useEffect(() => {
    const onScroll = function () {
      let box = document.getElementById("root");
      // this this is footer total - header height -footer height (not totally sure)
      const scroll_offset = footer_height - 50 - 50;
      if (
        window.innerHeight + window.scrollY >
        box.offsetHeight - scroll_offset
      ) {
        setReachedBottom(true);
      }
      if (
        window.innerHeight + window.scrollY <=
        box.offsetHeight - scroll_offset
      ) {
        setReachedBottom(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    var element = document.getElementById("footer");
    if (reachedBottom) {
      element.classList.add("footer-wrap-unstick");
    } else {
      element.classList.remove("footer-wrap-unstick");
    }
  }, [reachedBottom]);

  return (
    <>
      <div id="footer" className="footer-wrap">
        <Link to="/library" className="footer-link">
          Free Stories
        </Link>
        <Link to="/trial" className="footer-link">
          Join Free
        </Link>
      </div>

      <div className="footer-section" style={{ height: footer_height }}>
        <div className="footer-secondary">
          <div>
            <Link to="/contact" className="footer-secondary--link">
              Contact
            </Link>
            <Link to="/login" className="footer-secondary--link">
              My Account
            </Link>
            <Link to="/privacy-policy" className="footer-secondary--link">
              Privacy Policy
            </Link>
          </div>
          <div className="footer-icons">
            <a
              href="https://facebook.com/believeinbedtime"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon className="footer-fb-icon" />
            </a>
            <a
              href="https://instagram.com/believeinbedtime"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className="footer-insta-icon"
              />
            </a>
          </div>
        </div>

        <div className="footer-copyright">© 2022 Believe in Bedtime</div>
      </div>
    </>
  );
};

export default Footer;
