import React, { useState } from "react";
import "./subscribe.scss";

const Subscribe = () => {
  const [email, setEmail] = useState();

  return (
    <div className="subscribe-form">
      <div className="subscribe-form--title">Join Our Newsletter</div>
      <form
        action="https://believeinbedtime.us20.list-manage.com/subscribe/post"
        method="POST"
        target="_blank"
        noValidate
      >
        <input type="hidden" name="u" value="badb2fbad029e1efe4d953bf3" />
        <input type="hidden" name="id" value="3c9e8c22d3" />
        <label htmlFor="MERGE0">
          Email
          <input
            className="subscribe-email"
            type="email"
            name="EMAIL"
            id="MERGE0"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            autoCapitalize="off"
            autoCorrect="off"
          />
        </label>
        <input
          type="submit"
          value="Subscribe"
          name="subscribe"
          id="mc-embedded-subscribe"
          className="button subscribe-button"
        />

        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
          aria-label="Please leave the following three fields empty"
        >
          <label htmlFor="b_name">Name: </label>
          <input
            type="text"
            name="b_name"
            tabIndex="-1"
            value=""
            placeholder="Freddie"
            id="b_name"
          />

          <label htmlFor="b_email">Email: </label>
          <input
            type="email"
            name="b_email"
            tabIndex="-1"
            value=""
            placeholder="youremail@gmail.com"
            id="b_email"
          />

          <label htmlFor="b_comment">Comment: </label>
          <textarea
            name="b_comment"
            tabIndex="-1"
            placeholder="Please comment"
            id="b_comment"
          ></textarea>
        </div>
      </form>
    </div>
  );
};

export default Subscribe;
