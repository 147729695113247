import * as React from "react";
import Header from "../components/header";
import { Helmet } from "react-helmet";
// import Footer from "../components/footer";
import "./page-styles.scss";
import "./login.scss";

const Login = () => {
  return (
    <div>
      <Header />
      <Helmet
        link={[
          {
            rel: "canonical",
            key: "https://www.believeinbedtime.com/login",
            href: "https://www.believeinbedtime.com/login",
          },
        ]}
      >
        <title>Believe in Bedtime Login</title>
        <meta
          name="description"
          content="Login to view your account and manage your bedtime stories."
        />
      </Helmet>
      <div className="main-content">
        <div className="login-box">
          <h1 className="page-title">Login</h1>
          <input disabled />
          <input disabled />
          <div className="login-coming-soon">Coming Soon!</div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Login;
