import React, { useState } from "react";
import _ from "underscore";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Stars from "../components/stars";
import StoryWrapper from "../stories/story-wrapper";
import Stories from "../story_db.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import "./story-product.scss";
import StorySetup from "../components/story-setup";
import SocialShare from "../components/socialshare";
import Review from "../components/review";

const StoryProduct = () => {
  const all_stories = Stories.data;
  let { slug } = useParams();
  const story = _.findWhere(all_stories, { slug: slug }) || {};

  const [kid_name, setKidName] = useState();
  const [kid_gender, setKidGender] = useState();

  const [stuffy_name, setStuffyName] = useState();
  const [stuffy_gender, setStuffyGender] = useState();

  // options are: step1, step2, story
  const [current_step, setCurrentStep] = useState("step1");

  const StoryInfo = () => {
    return (
      <div className="story-top">
        <Helmet
          link={[
            {
              rel: "canonical",
              key: "https://www.believeinbedtime.com/product/" + { slug },
              href: "https://www.believeinbedtime.com/product/" + { slug },
            },
          ]}
        >
          <title>{story.title}</title>
          <meta
            name="description"
            content={story.descriptive_subtitle || story.descriptive_title}
          />
        </Helmet>
        <h1 className="story-title">{story.title || ""}</h1>
        <Stars star_number={story.review_average} />
        <div>Length: {story.length} min</div>
        <div>Ages: 4-8</div>
        <div className="story-product-info">{story.product_info}</div>
      </div>
    );
  };

  const StoryBody = () => {
    switch (current_step) {
      case "step1":
        return (
          <>
            {StoryInfo()}
            <StorySetup
              key="kid"
              input_label="Child's Name"
              step={1}
              name={kid_name}
              setName={setKidName}
              gender={kid_gender}
              setGender={setKidGender}
              slug={slug}
              changeStep={() => setCurrentStep("step2")}
            />
          </>
        );
      case "step2":
        return (
          <>
            {StoryInfo()}
            <StorySetup
              key="stuffy"
              input_label="Stuffed Animal or Favorite Toy's Name"
              step={2}
              name={stuffy_name}
              setName={setStuffyName}
              gender={stuffy_gender}
              setGender={setStuffyGender}
              slug={slug}
              changeStep={() => setCurrentStep("story")}
            />
          </>
        );
      case "story":
        //redirect in future to hidden url??
        return (
          <>
            <StoryWrapper
              slug={slug}
              kid_name={kid_name}
              kid_gender={kid_gender}
              stuffy_name={stuffy_name}
              stuffy_gender={stuffy_gender}
            />
            <div className="share-review-block">
              <div className="share-text">
                Did you enjoy the story? Leave a review or share on social media
              </div>
              <div className="share-review-sections">
                <Review slug={slug} />
                <SocialShare />
              </div>
            </div>
          </>
        );
      default:
        return "foo";
    }
  };

  // return 401 if no slug
  return (
    <>
      <Header />
      {story.locked ? (
        <div className="main-content story-page">
          {StoryInfo()}
          <div className=" trial-page trial-product-page">
            <div className="trial-title">TRY ONE MONTH FREE</div>
            <a href="#" className="trial-button" disabled>
              Try for $0.00
            </a>
            <div className="coming-soon-text">Coming Soon!</div>
            <div className="trial-subtext">
              $3.95 a month after 30 days. Cancel anytime.
            </div>
            <div className="trial-checklist">
              <div className="trial-check-item">
                <FontAwesomeIcon icon={faCheck} className="trial-check-icon" />
                80+ Believe in Bedtime stories
              </div>
              <div className="trial-check-item">
                <FontAwesomeIcon icon={faCheck} className="trial-check-icon" />
                Access anytime, anywhere
              </div>
              <div className="trial-check-item">
                <FontAwesomeIcon icon={faCheck} className="trial-check-icon" />
                Try it free for one month
              </div>
              <div className="trial-check-item">
                <FontAwesomeIcon icon={faCheck} className="trial-check-icon" />
                Cancel online anytime
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-content story-page">
          {!_.isEmpty(story) ? StoryBody() : <div>Story not Found</div>}
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
};

export default StoryProduct;
