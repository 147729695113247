import * as React from "react";
import PropTypes from "prop-types";
import "./review.scss";

const Review = ({ slug }) => {
  const getLink = () => {
    switch (slug) {
      case "collector1":
      case "collector2":
        return "https://us20.list-manage.com/survey?u=badb2fbad029e1efe4d953bf3&id=295b4daebe&attribution=false";
      case "wrong-tower1":
      case "wrong-tower2":
        return "https://us20.list-manage.com/survey?u=badb2fbad029e1efe4d953bf3&id=bdb0c7ca7e&attribution=false";
      case "mrwiggleworm":
        return "https://us20.list-manage.com/survey?u=badb2fbad029e1efe4d953bf3&id=4017472955&attribution=false";
      case "longjourney1":
      case "longjourney2":
      case "longjourney3":
      case "longjourney4":
      case "longjourney5":
      case "longjourney6":
      case "longjourney7":
        return "https://us20.list-manage.com/survey?u=badb2fbad029e1efe4d953bf3&id=fdad21d3d2&attribution=false";
      default:
        return "";
    }
  };

  return (
    <div>
      <a
        href={getLink()}
        target="_blank"
        className="leave-review-text"
        rel="noreferrer"
      >
        Leave a Review
      </a>
    </div>
  );
};

Review.propTypes = {
  slug: PropTypes.string,
};

export default Review;
